import React from 'react';

import theme from 'shared/assets/style/theme';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

import { styles, FormWrapper } from './../styledComponents';
import AutoFormInput from '../../../../components/AutoFormInput';
import TermsModalWrapper from '../../../../components/TermsModal/TermsModalComponents/TermsModalWrapper';
import Button from '../../../../components/Button/Button';

function DefaultForm({ handleSubmit, email, success, error, handleCancel, themeContext, handleInputChange }) {
  return (
    <TermsModalWrapper
      isVisible={true}
      closeModal={handleCancel}
      title={getText(TEXT_KEY.FORGOT_PASSWORD_TITLE)}
      zIndex={101}
      bodyStyle={themeContext.ForgotPassword.ModalBody}
      modalContainerStyle={themeContext.ForgotPassword.ModalContainer}
    >
      <div style={styles.modal}>
        <p style={{ textAlign: 'center', marginTop: 20 }}>{getText(TEXT_KEY.ENTER_YOUR_REGISTERED_EMAIL)} </p>

        {!success && error && (
          <p
            style={{
              textAlign: 'center',
              color: theme.palette.error[1],
            }}
          >
            {error}
          </p>
        )}

        {success && (
          <p
            style={{
              textAlign: 'center',
              color: theme.palette.success[1],
            }}
          >
            {getText(TEXT_KEY.ACCOUNT_HAS_BEEN_LINKED)}
          </p>
        )}

        {!success && (
          <FormWrapper>
            <AutoFormInput
              customClassName="transparentInput"
              emitInput={e => handleInputChange(e, 'email')}
              value={email}
              label="Email"
              type="text"
              trimInput={true}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button
                imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                containerStyle={{ marginTop: 20 }}
                label={getText(TEXT_KEY.SUBMIT)}
                onClick={() => handleSubmit({ email })}
              />
              <Button
                imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_DANGER)}
                containerStyle={{ marginTop: 20 }}
                label={getText(TEXT_KEY.CANCEL)}
                onClick={handleCancel}
              />
            </div>
          </FormWrapper>
        )}
      </div>
    </TermsModalWrapper>
  );
}

export default DefaultForm;

