import styled from 'styled-components';
import Button from '../../../components/Button/Button';
import generateRender from 'shared/utils/generateRender';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

const LoginButtonDefault = styled(Button).attrs(({ isValid }) => ({
  imageSource: isValid ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY) : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED),
  containerStyle: { height: '100px' },
}))`
  cursor: pointer;
  font-size: 1.7rem;
  font-weight: 600;
  width: 181px;
  height: 59px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
`;

const LoginButtonMillelacs = styled(Button).attrs(({ isValid }) => ({
  imageSource: isValid
    ? assetSource(ASSET_KEY.ROUNDEDRECT_PRIMARY_WIDE)
    : assetSource(ASSET_KEY.ROUNDEDRECT_DISABLED_WIDE),
  imageStyle: {
    width: '35vh',
    height: '6vh',
  },
  containerStyle: { height: '6vh', marginTop: '30px' },
}))`
  cursor: pointer;
  font-size: 1.7rem;
  font-weight: 600;
  width: 181px;
  height: 59px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
`;

const LoginButton = generateRender({
  default: LoginButtonDefault,
  millelacs: LoginButtonMillelacs,
  ladyluck: LoginButtonMillelacs,
});

export default LoginButton;

