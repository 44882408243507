import React, { Component } from 'react';
import styled from 'styled-components';
import SWatchAndEarnInfo from 'shared/screens/VideoAds/Info';
import TimeRemainingText from '../../components/TimeRemainingText';
import './VideoAds.scss';
import Utils from 'shared/utils/index';
import theme from 'shared/assets/style/theme';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ModalHeader from './components/WatchAndEarnHeader';
import ThemeContext from 'shared/context/ThemeContext';
import { HeaderContainer, HeaderHeight, wrapperStyle, HeaderTitleWrapperStyle } from './components/styledComponents';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default class WatchAndEarnInfo extends Component {
  static get AsModalProps() {
    return {
      dialogClassName: 'gtp-modal-watchAndEarn-info',
    };
  }

  componentDidMount() {
    window.history.replaceState('', '', window.location.origin);
  }

  render() {
    const themeContext = this.context;
    return (
      <SWatchAndEarnInfo
        navigation={this.props.navigation}
        render={args => {
          return (
            <div className={'watchAndEarnInfoContainer' + (Utils.ieVersion() !== 0 ? ' ieHack' : '')}>
              <ModalHeader
                headerTitleWrapperStyle={HeaderTitleWrapperStyle}
                headerHeightStyle={HeaderHeight}
                wrapperStyle={wrapperStyle}
                containerStyle={HeaderContainer}
                title={getText(TEXT_KEY.COME_BACK_LATER)}
              />
              <span className="watchAndEarnPopupIcon">
                <WatchAndEarnIcon />
              </span>
              <AlreadyWatchedText>{getText(TEXT_KEY.ALREADY_WATCHED_ALL)}</AlreadyWatchedText>
              <SecondDivider />
              <ComeBackInText>{getText(TEXT_KEY.COME_BACK_IN)}</ComeBackInText>
              <span className="timerContainer">
                <TimerIcon />
                <TimeRemainingText
                  style={themeContext.WatchAndEarnInfo.TimeRemainingText}
                  className="timeRemaining"
                  until={args.videoAds.refreshDate}
                />
              </span>
            </div>
          );
        }}
      />
    );
  }
}

WatchAndEarnInfo.hideOverlay = true;
WatchAndEarnInfo.contextType = ThemeContext;

const WatchAndEarnIcon = styled.img.attrs(() => ({
  src: assetSource(ASSET_KEY.WATCH_EARN_POPUP_ICON),
}))`
  width: 100%;
  height: 100%;
`;

const AlreadyWatchedText = styled.span`
  font-size: 1em;
  margin-top: -2.5em;
  width: 90%;
  text-align: center;
  text-shadow: ${theme.palette.primary[16]} 1px 1px 4px;
`;

const ComeBackInText = styled.span`
  font-size: 1.1em;
  text-shadow: ${theme.palette.primary[16]} 1px 1px 4px;
`;

const TimerIcon = styled.img.attrs(() => ({
  src: assetSource(ASSET_KEY.TIMER_BACKING),
}))`
  width: 100%;
  height: 100%;
`;

const SecondDivider = styled.img.attrs(() => ({
  src: assetSource(ASSET_KEY.SHOP_VIP_DIVIDER),
}))`
  width: 50%;
  height: 0.5vmin;
  margin-top: 1vmin;
  margin-bottom: 0.8vmin;
`;

