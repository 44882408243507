import React, { Component } from 'react';
import DefaultTooltip from './DefaultTooltip';
import FtueTooltip from './FtueTooltip';
import MissionsTooltip from './MissionsTooltip';
import EventsTooltip from './EventsTooltip';
import STooltip from 'shared/components/Tooltip';

const TooltipComponents = {
  coinBar: DefaultTooltip,
  xpBar: DefaultTooltip,
  tierBar: DefaultTooltip,
  tierComingSoon: DefaultTooltip,
  loyaltyBar: DefaultTooltip,
  ftue: FtueTooltip,
  missions: MissionsTooltip,
  events: EventsTooltip,
};

export default class Tooltip extends Component {
  render() {
    return (
      <STooltip
        {...this.props}
        render={args => {
          const { visible } = args;
          const { popUpType = '' } = this.props;

          if (!visible) {
            return <></>;
          }

          const SelectedTooltip = popUpType ? TooltipComponents[popUpType] : DefaultTooltip;
          return <SelectedTooltip props={{ ...this.props, ...args }} />;
        }}
      />
    );
  }
}
