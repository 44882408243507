import React from 'react';
import { StyledFlickerText, StyledTimerText } from './styledComponents';
import { counterTypes } from 'shared/utils/TimestampManager';

export default function DefaultText({
  style,
  string,
  isTimeInDanger,
  className,
  flickeringEffectSpeed = 1000,
  counterType,
  inNavBar,
}) {
  return (
    <>
      {counterType === counterTypes.MISSION_CARD && inNavBar ? (
        <StyledFlickerText
          style={style}
          className={className}
          isTimeInDanger={isTimeInDanger}
          flickeringEffectSpeed={flickeringEffectSpeed}
        >
          {string}
        </StyledFlickerText>
      ) : (
        <StyledTimerText style={style} className={className}>
          {string}
        </StyledTimerText>
      )}
    </>
  );
}

