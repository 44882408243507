import Missions from './Missions';
import { connect } from 'shared/node_modules/react-redux';

const mapStateToProps = state => {
  return {
    missionsData: state.missions.data,
    missionsFeature: state.missions.missionsFeature,
    missionsAssets: state.assetsPackage.assets.MISSIONS_FEATURE,
    assetsLoaded: state.assetsPackage.assetsLoaded,
    tutorialStep: state.tutorial.tutorialStep,
    activeTutorial: state.tutorial.activeTutorial,
    clientProgress: state.missions.currentMission.clientProgress,
  };
};

export default connect(mapStateToProps)(Missions);

