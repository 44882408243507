import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'PROPERTIES';
var initialState = {
  data: {
    defaultSignupFormSettings: {},
    enterRequirements: [],
    propertySelector: [],
    userProperty: null,
    showLinkLoyaltyCardSetting: true,
    enableOrderCard: false,
    linkCards: null,
    dailyWheelCard: null,
    cardLinkPopupImage: null,
    playerPropertyLoyaltyCardType: null,
    isTermsUpdated: null,
    loyaltyCardComingSoon: false,
    textsFile: require("../../../assets/".concat("hri", "/texts/texts.json"))
  },
  errorMsg: ''
};
var propertiesSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    propertiesRequestDataSuccess: function propertiesRequestDataSuccess(state, action) {
      state.isFetchingData = false;
      state.data = _objectSpread(_objectSpread({}, state.data.textsFile), action.payload);
    },
    setUpdatedTermsState: function setUpdatedTermsState(state, action) {
      state.data.isTermsUpdated = action.payload;
    }
  }
});
var actions = propertiesSlice.actions,
  reducer = propertiesSlice.reducer;
var propertiesRequestData = actions.propertiesRequestData,
  propertiesIsFetching = actions.propertiesIsFetching,
  propertiesRequestError = actions.propertiesRequestError,
  propertiesRequestDataSuccess = actions.propertiesRequestDataSuccess,
  setUpdatedTermsState = actions.setUpdatedTermsState;
export { propertiesRequestData, propertiesIsFetching, propertiesRequestError, propertiesRequestDataSuccess, setUpdatedTermsState };
export default reducer;