import styled, { css } from 'styled-components';
import theme from 'shared/assets/style/theme';

const errorBorder = css`
  border: 1px solid red;
`;

const BoxErrorLayout = css`
  border-bottom-left-radius: 0.6em;
  border-bottom-right-radius: 0.6em;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: none;
  &:focus {
    border-bottom-left-radius: 0.6em;
    border-bottom-right-radius: 0.6em;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: none;
  }
`;

export const TextInput = styled.input`
  width: 100%;
  height: 42px;
  text-align: left;
  padding-left: 1em;
  box-shadow: none;
  background-color: unset;
  outline: none;
  padding-left: ${({ icon }) => (icon ? 30 : 10)}px;
  color: white;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${theme.palette.common[1]};
  }
  ${({ theme }) => theme}
  ${({ error, boxErrorLayout }) => error && !boxErrorLayout && errorBorder}
  ${({ error, boxErrorLayout }) => error && boxErrorLayout && BoxErrorLayout}
`;

