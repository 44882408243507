import { SET_ASSET_BASE_URL, SET_APP_BACKGROUND, SET_ASSETS_URLS, SET_TARGET, SHOW_CUSTOM_MODAL, SET_CACHE_ID, SET_RESTART_SESSION, SET_CURRENT_SCREEN, SET_LANGUAGE, LOGOUT } from './types';
export var setAssetBaseUrl = function setAssetBaseUrl(assetBaseUrl) {
  return {
    type: SET_ASSET_BASE_URL,
    payload: assetBaseUrl
  };
};
export var setAppBackground = function setAppBackground(background) {
  return {
    type: SET_APP_BACKGROUND,
    payload: background
  };
};
export var setAssetsUrls = function setAssetsUrls(assetsUrls) {
  return {
    type: SET_ASSETS_URLS,
    payload: assetsUrls
  };
};
export var setTarget = function setTarget(target) {
  return {
    type: SET_TARGET,
    payload: target
  };
};
export var showCustomModal = function showCustomModal(value) {
  return {
    type: SHOW_CUSTOM_MODAL,
    payload: value
  };
};
export var setCacheId = function setCacheId(cacheId) {
  return {
    type: SET_CACHE_ID,
    payload: cacheId
  };
};
export var setRestartSession = function setRestartSession(data) {
  return {
    type: SET_RESTART_SESSION,
    payload: data
  };
};
export var setCurrentScreen = function setCurrentScreen(value) {
  return {
    type: SET_CURRENT_SCREEN,
    payload: value
  };
};
export var setAppLanguage = function setAppLanguage(language) {
  return {
    type: SET_LANGUAGE,
    payload: language
  };
};
export var clearState = function clearState() {
  var payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    type: LOGOUT,
    payload: payload
  };
};