import { useReducer } from 'react';
import { RESET_PASS_ACTIONS } from '../constants';
import { usePostRecoverPasswordMutation } from 'shared/state/query/player';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import RouteParamConstants from 'shared/utils/routeParamConstants';
import Validators from 'shared/components/Form/InputValidator';

function useResetPasswordHandler({ getText, TEXT_KEY }) {
  const [passwordData, dispatch] = useReducer(reducer, initialState);
  const [postRecoverPassword] = usePostRecoverPasswordMutation();

  const { password, confirmPassword } = passwordData;

  const isBodyValid = () =>
    Validators.NOT_EMPTY(password) && Validators.PASSWORD(password) && password === confirmPassword;

  const onSumbitButtonClick = async (id, token) => {
    if (!isBodyValid()) {
      dispatch({ type: RESET_PASS_ACTIONS.INVALID_BODY, payload: getText(TEXT_KEY.INVALID_BODY_MESSAGE) });
      return false;
    }
    dispatch({ type: RESET_PASS_ACTIONS.SENDING_DATA, payload: getText(TEXT_KEY.SENDING_NEW_PASSWORD_REQUEST) });
    const passwordChangeReq = await postRecoverPassword({ id, token, password }).unwrap();
    if (passwordChangeReq.success) {
      dispatch({ type: RESET_PASS_ACTIONS.SUCCESS_RESPONSE, payload: passwordChangeReq.msg });
      QueryParamsProxy.setQueryParams({ [RouteParamConstants.PARAM_RECOVER_PASS_ID]: undefined });
      QueryParamsProxy.setQueryParams({ [RouteParamConstants.PARAM_RECOVER_PASS_TOKEN]: undefined });
      QueryParamsProxy.setQueryParams({ [RouteParamConstants.PARAM_RECOVER_PASS_PLATFORM]: undefined });
    } else {
      dispatch({ type: RESET_PASS_ACTIONS.ERROR_RESPONSE, payload: passwordChangeReq.error });
    }
  };

  return {
    passwordData,
    dispatch,
    onSumbitButtonClick,
  };
}

export default useResetPasswordHandler;

const initialState = {
  password: '',
  confirmPassword: '',
  error: false,
  message: '',
  response: false,
  loading: false,
  showPasswords: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case RESET_PASS_ACTIONS.UPDATE_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case RESET_PASS_ACTIONS.CONFIRM_PASSWORD:
      return {
        ...state,
        confirmPassword: action.payload,
      };
    case RESET_PASS_ACTIONS.SENDING_DATA:
      return {
        ...state,
        loading: true,
        error: false,
        message: action.payload,
      };
    case RESET_PASS_ACTIONS.INVALID_BODY:
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    case RESET_PASS_ACTIONS.SUCCESS_RESPONSE:
      return {
        ...state,
        error: false,
        response: true,
        loading: false,
        message: action.payload,
      };
    case RESET_PASS_ACTIONS.ERROR_RESPONSE:
      return {
        ...state,
        error: true,
        response: true,
        loading: false,
        message: action.payload,
      };
    case RESET_PASS_ACTIONS.TOGGLE_PASSWORD:
      return {
        ...state,
        showPasswords: !state.showPasswords,
      };
    default:
      return state;
  }
};

