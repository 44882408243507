import React, { Component } from 'react';
import { UpdateTermsBackgroundImage } from './styledComponents';
import SUpdateTerms from 'shared/screens/UpdateTerms';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import Terms from '../../../components/TermsAndConditions/TextLinksTermsLayout';

export default class TextLinksTermsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oauthLoading: false,
      statusMessage: null,
      emailPermissionAccepted: null,
    };
  }

  render() {
    return (
      <SUpdateTerms
        navigation={this.props.navigation}
        {...this.props}
        render={args => {
          const { setTerms, terms, onSubmit, error, disableClose, termsAndConditionsUrl, privacyPolicyUrl } = args;
          return (
            <>
              <UpdateTermsBackgroundImage alt="background" src={assetSource(ASSET_KEY.SPLASH_BG)} />
              <Terms
                isVisible={true}
                title={getText(TEXT_KEY.TERMS_AND_CONDITIONS_TITLE)}
                disableClose={disableClose}
                hideCloseBtn={true}
                setTerms={setTerms}
                terms={terms}
                onSubmit={onSubmit}
                error={error}
                termsAndConditionsUrl={termsAndConditionsUrl}
                privacyPolicyUrl={privacyPolicyUrl}
                mainText={getText(TEXT_KEY.TERMS_UPDATE_SCREEN_TEXT)}
                removeLeftPart
              />
            </>
          );
        }}
      />
    );
  }
}

