import generateRender from '../../../utils/generateRender';
import { BUTTON_STROKES } from '../../../utils/buttonTextStrokes';
export var messagePinkButtonStroke = generateRender({
  default: BUTTON_STROKES.GREEN,
  star: BUTTON_STROKES.PINK
});
export var messageOrangeButtonStroke = generateRender({
  default: BUTTON_STROKES.TERTIARY,
  hri: BUTTON_STROKES.PURPLE,
  admes: BUTTON_STROKES.PURPLE,
  admiral: BUTTON_STROKES.PURPLE,
  ti: BUTTON_STROKES.PINK,
  millelacs: BUTTON_STROKES.BLUE,
  jamul: BUTTON_STROKES.GREEN,
  ladyluck: BUTTON_STROKES.GREEN
});