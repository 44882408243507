import React from 'react';
import { useSelector } from 'shared/node_modules/react-redux';
import { EventsEventTextWrapper, TooltipBackground } from './styledComponent';
import useGenerateEventsIconRender from 'shared/screens/Events/hooks/useGenerateEventsIconRender';

export default function EventsTooltip({ props }) {
  const { displayText, style } = props;

  const eventsAssets = useSelector(state => state.assetsPackage?.assets?.EVENTS_FEATURE);

  const { EVENTS_DEFAULT_TOOLTIP } = useGenerateEventsIconRender({ eventsAssets });
  const tooltipBG = eventsAssets?.EVENTS_TOOTLIP ?? EVENTS_DEFAULT_TOOLTIP;

  return (
    <div className="Events-tooltip AnimationFadeIn" style={style}>
      <TooltipBackground src={tooltipBG} />
      <EventsEventTextWrapper>{displayText}</EventsEventTextWrapper>
    </div>
  );
}

