import React from 'react';
import styled from 'styled-components';

const RewardCenterCard = ({ children, handleClick, Frame }) => {
  return (
    <Container>
      <CardClickable onClick={handleClick}>
        <CardContainer background={Frame}>
          <CardFrame background={Frame}>{children}</CardFrame>
        </CardContainer>
      </CardClickable>
    </Container>
  );
};

export default RewardCenterCard;

const Container = styled.div`
  cursor: pointer;
  height: 100%;
  margin-top: 6rem;
  @media only screen and (max-height: 650px) {
    margin-top: 2rem;
  }
  @media only screen and (min-height: 650px) and (max-height: 985px) {
    margin-top: 3rem;
  }
`;

const CardClickable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardContainer = styled.div`
  height: 40rem;
  width: 24rem;
  display: flex;
  align-items: center;
  z-index: 8;
  flex-direction: column;
  @media only screen and (max-height: 575px) {
    height: 17rem;
    width: 11rem;
  }

  @media only screen and (min-height: 575px) and (max-height: 650px) {
    height: 20rem;
    width: 12rem;
  }

  @media only screen and (min-height: 650px) and (max-height: 800px) {
    height: 25rem;
    width: 15rem;
  }

  @media only screen and (min-height: 800px) and (max-height: 900px) {
    height: 33rem;
    width: 20rem;
  }
`;

const CardFrame = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${({ background }) => `url(${background})`};
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
  }
`;

