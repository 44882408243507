import React from 'react';
import { SubTitle } from '../styledComponents';

function DefaultLinkAccountHeader({ title, subTitle, theme }) {
  return (
    <>
      <span style={theme}>{title}</span>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </>
  );
}

export default DefaultLinkAccountHeader;

