const Styles = {
  fottetButtonStyle: {
    width: window.innerWidth < 1400 && window.innerHeight < 800 ? '255px' : '295px',
    height: window.innerWidth < 1400 && window.innerHeight < 800 ? '45px' : '55px',
    cursor: 'pointer',
  },
  NoAssetLayoutModalStyle: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 50vmin',
  },
  NoAssetLayoutModalBodyStyle: { height: '40%' },
  NoAssetLayoutModalContainerStyle: {
    height: '30vh',
  },
};

export default Styles;

