import styled from 'styled-components';

const BoxWithBorderGradient = styled.div`
  position: relative;
  border-radius: ${({ borderRadius }) => borderRadius || 0}px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: ${({ borderRadius }) => borderRadius || 0}px;
    padding: ${({ borderWidth }) => borderWidth || 0}px;
    background: ${({ borderGradient }) =>
      borderGradient || `linear-gradient(to bottom, transparent, transparent)`}; /* Gradient border */
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: xor;
    z-index: -1;
  }
`;

export default BoxWithBorderGradient;

