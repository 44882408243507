import React, { useContext } from 'react';
import Button from '../../../../../../components/Button/Button';
import Modal from '../../../../../../components/JSModal';
import { PURCHASE_RESPONSE } from 'shared/constants';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import {
  ContentWrapper,
  highlightedHeaderContainerStyle,
  highlightedHeaderHeight,
  errorModalMainBodyStyle,
  IconWrapper,
  VipSuccessAssetWrapper,
  VipSuccessAsset,
  MainContainer,
  MessageContainer,
  MessageIcon,
  modalStyle,
  successModalMainBodyStyle,
  VipPurchaseSuccessAccessText,
} from './styledComponents';
import ThemeContext from 'shared/context/ThemeContext';
import RespopnseHeader from './header';

const PurchaseResponsePopUp = ({
  purchaseResponse,
  onDismissPurchaseResponse,
  setIsPurchaseButtonDisabled,
  rewardCenterAssets,
  isVipSuccessPurchase,
  onVipOfferPlayButton,
}) => {
  const RENDER_CONFIG = {
    [PURCHASE_RESPONSE.SUCCESS]: {
      headerText: getText(TEXT_KEY.CONGRATULATIONS),
      assetSource: isVipSuccessPurchase
        ? assetSource(ASSET_KEY.PURCHASE_SUCCEEDED)
        : rewardCenterAssets.REWARD_CENTER_PURCHASE_SUCCESS,
      buttonText: isVipSuccessPurchase ? getText(TEXT_KEY.PLAY_NOW) : getText(TEXT_KEY.DONE),
      modalStyle: successModalMainBodyStyle,
    },
    [PURCHASE_RESPONSE.ERROR]: {
      headerText: getText(TEXT_KEY.OOPS),
      buttonText: getText(TEXT_KEY.OK),
      modalStyle: errorModalMainBodyStyle,
    },
  };

  const renderConfig = RENDER_CONFIG[purchaseResponse.status] || {};

  const handleDismissPurchaseResponse = () => {
    onDismissPurchaseResponse();
    setIsPurchaseButtonDisabled(false);
  };

  const themeContext = useContext(ThemeContext);

  return (
    <Modal
      isVisible={true}
      onBackButtonPress={() => onDismissPurchaseResponse(null)}
      style={modalStyle}
      modalMainBodyStyle={renderConfig.modalStyle}
      hideCloseButton={isVipSuccessPurchase ? false : true}
    >
      <MainContainer>
        <MessageContainer>
          {!isVipSuccessPurchase && (
            <RespopnseHeader
              containerStyle={highlightedHeaderContainerStyle}
              wrapperStyle={themeContext.OfferDetail.PurchaseResponseHighlightHeader}
              headerTitleWrapperStyle={themeContext.OfferDetail.PurchaseResponseHeader}
              dividerAsset={assetSource(ASSET_KEY.SHOP_VIP_DIVIDER_GLOWS)}
              headerHeightStyle={highlightedHeaderHeight}
              title={renderConfig.headerText}
            />
          )}
          <ContentWrapper>
            {renderConfig.assetSource &&
              (isVipSuccessPurchase ? (
                <VipSuccessAssetWrapper>
                  <VipSuccessAsset alt="" src={renderConfig.assetSource} />
                </VipSuccessAssetWrapper>
              ) : (
                <IconWrapper>
                  <MessageIcon alt="" src={renderConfig.assetSource} />
                </IconWrapper>
              ))}
            {isVipSuccessPurchase && (
              <VipPurchaseSuccessAccessText>{getText(TEXT_KEY.ACCESS_GRANTED)}</VipPurchaseSuccessAccessText>
            )}
            <div style={themeContext.OfferDetail.RewardCenterPurchaseResponseMessage}>{purchaseResponse.message}</div>
            <Button
              onClick={isVipSuccessPurchase ? onVipOfferPlayButton : handleDismissPurchaseResponse}
              containerStyle={{
                margin: '1em auto',
                position: 'relative',
                textAlign: 'center',
                cursor: 'pointer',
                pointerEvents: 'auto',
                width: '10em',
                height: '14%',
                paddingTop: purchaseResponse.status === PURCHASE_RESPONSE.SUCCESS ? 0 : '3.4em',
              }}
              imageStyle={{ width: '100%', height: '100%' }}
              imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
              label={renderConfig.buttonText}
            />
          </ContentWrapper>
        </MessageContainer>
      </MainContainer>
    </Modal>
  );
};

export default PurchaseResponsePopUp;

