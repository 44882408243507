import theme from 'shared/assets/style/theme';

const Styles = {
  modalStyle: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20vmin',
  },
  socialModalStyle: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 0',
  },
  SocialModalContainerStyle: {
    height: '40vh',
    width: '100%',
    marginTop: '-1vw',
    marginBottom: '-1vw',
  },
  modalContainerStyle: {
    height: '60vh',
    width: '100%',
    marginTop: '-1vw',
    marginBottom: '-1vw',
  },
  titleStyle: {
    fontSize: '24px',
    fontWeight: '600',
    color: theme.palette.common[1],
    textTransform: 'uppercase',
  },
  modalMainBodyStyle: {
    width: '1000px',
  },
  markImage: {
    width: '12px',
    height: '12px',
  },
  guestButtonStyle: {
    width: '270px',
    height: '45px',
  },
  middleDesignButtonStyle: {
    width: '35vh',
    height: '6vh',
  },
};

export default Styles;

