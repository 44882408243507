import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import FormValidator from 'shared/components/FormValidator';
import Validators from 'shared/components/Form/InputValidator';
import AutoFormInput from '../../../components/AutoFormInput';
import FormType from 'shared/components/Form/FormType';
import FormId from 'shared/components/Form/FormId';
import './PaymentInfo.scss';
import { COUNTRY, STATES } from 'shared/utils/kyc/Constants';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import Button from '../../../components/Button/Button';
import { getDefaultCountryValue } from 'shared/utils/kyc/getDefaultCountry';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import ThemeContext from 'shared/context/ThemeContext';

export default class DefalutPaymentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: getDefaultCountryValue,
    };
  }

  getAutoFormInput = (headerText, valid, newDataChangeHandle, component, validator, formType) => {
    const { PurchaseFlow } = this.context;
    return (
      <>
        <AutoFormInput
          customClassName="transparentInput"
          controlId={component}
          id={component}
          validator={validator}
          type={formType}
          keyboardHint={''}
          label={headerText}
          validated={valid[component]}
          emitInput={newDataChangeHandle}
          customStyle={PurchaseFlow.PaymentInfoFields}
        />
      </>
    );
  };

  getInputWithDropdown = (
    headerText,
    valid,
    newDataChangeHandle,
    component,
    validator,
    formType,
    optionAvailable,
    isToSelectCountry = false
  ) => {
    const updateSelectedCountry = e => {
      this.setState({
        selectedCountry: e.target.value ? e.target.value : getDefaultCountryValue,
      });
    };
    const { PurchaseFlow } = this.context;
    return (
      <>
        <div className="inputWithDropdown">
          <AutoFormInput
            customClassName="transparentInput"
            controlId={component}
            id={component}
            validator={validator}
            type={formType}
            keyboardHint={''}
            label={headerText}
            options={optionAvailable}
            validated={valid[component]}
            emitInput={newDataChangeHandle}
            getItemValue={isToSelectCountry ? updateSelectedCountry : false}
            customStyle={PurchaseFlow.PaymentInfoFields}
          />
          <img
            style={PurchaseFlow.DownArrowIcon}
            className="downIcon"
            src={assetSource(ASSET_KEY.CARET_DOWN)}
            alt="caret_down"
          />
        </div>
      </>
    );
  };

  onValidationFailed = nextHandle => {
    nextHandle(false);
  };

  render() {
    const { nextHandle, onSubmit, errorMessage } = this.props;
    return (
      <FormValidator
        schema={{
          [FormId.ADDRESS_1]: Validators.NOT_EMPTY,
          [FormId.ADDRESS_2]: Validators.NO_VALIDATION,
          [FormId.COUNTRY]: Validators.NOT_EMPTY,
          [FormId.CITY]: Validators.NOT_EMPTY,
          [FormId.STATE]: Validators.NOT_EMPTY,
          [FormId.ZIP]: Validators.NOT_EMPTY,
        }}
        key="billing-form-2"
        onSubmit={validatedData => onSubmit(nextHandle, validatedData)}
        onValidationFailed={() => this.onValidationFailed(nextHandle)}
        render={({ data, valid, complete, newDataChangeHandle, submitHandle }) => (
          <Form className="form-container payment-info" onSubmit={submitHandle}>
            <TopTowContainer className="dataContainer">
              <span className="message">{getText(TEXT_KEY.AGE_RESTRICTION)}</span>
            </TopTowContainer>
            <Row className="dataContainer">
              <Col>
                <div className="inputField">
                  <label className="inputLabel">
                    {this.getAutoFormInput(
                      getText(TEXT_KEY.PAYMENT_INFO_ADDRESS_LINE_1),
                      valid,
                      newDataChangeHandle,
                      FormId.ADDRESS_1,
                      Validators.NOT_EMPTY,
                      FormType.TEXT
                    )}
                  </label>
                </div>
              </Col>
            </Row>
            <RowContainerWithLessSpace className="dataContainer">
              <Col>
                <div className="inputField">
                  <label className="inputLabel">
                    {this.getAutoFormInput(
                      getText(TEXT_KEY.PAYMENT_INFO_ADDRESS_LINE_2),
                      valid,
                      newDataChangeHandle,
                      FormId.ADDRESS_2,
                      Validators.NO_VALIDATION,
                      FormType.TEXT
                    )}
                  </label>
                </div>
              </Col>
            </RowContainerWithLessSpace>
            <RowContainerWithLessSpace className="dataContainer">
              <Col>
                <div className="inputField">
                  <label className="inputLabel">
                    {this.getInputWithDropdown(
                      getText(TEXT_KEY.PAYMENT_INFO_COUNTRY),
                      valid,
                      newDataChangeHandle,
                      FormId.COUNTRY,
                      Validators.NOT_EMPTY,
                      FormType.DROPDOWN,
                      COUNTRY(),
                      true
                    )}
                  </label>
                </div>
              </Col>
            </RowContainerWithLessSpace>
            <Row className="dataContainer">
              <Col>
                <div className="inputField">
                  <label className="inputLabel">
                    {this.getAutoFormInput(
                      getText(TEXT_KEY.PAYMENT_INFO_CITY),
                      valid,
                      newDataChangeHandle,
                      FormId.CITY,
                      Validators.NOT_EMPTY,
                      FormType.TEXT
                    )}
                  </label>
                </div>
              </Col>
            </Row>
            <Row className="dataContainer">
              <Col>
                <div className="inputField">
                  <label className="halfInputField">
                    {this.getInputWithDropdown(
                      getText(TEXT_KEY.PAYMENT_INFO_STATE_PROVINCE),
                      valid,
                      newDataChangeHandle,
                      FormId.STATE,
                      Validators.NOT_EMPTY,
                      FormType.DROPDOWN,
                      STATES[this.state.selectedCountry]()
                    )}
                  </label>
                </div>
              </Col>
              <Col>
                <div className="inputField">
                  <label className="halfInputField">
                    {this.getAutoFormInput(
                      getText(TEXT_KEY.PAYMENT_INFO_ZIP),
                      valid,
                      newDataChangeHandle,
                      FormId.ZIP,
                      Validators.NOT_EMPTY,
                      FormType.TEXT
                    )}
                  </label>
                </div>
              </Col>
            </Row>
            <MessageHolder className="dataContainer">
              {errorMessage && <div className="message">{errorMessage}</div>}
            </MessageHolder>
            <Row className="dataContainer">
              <Button
                imageSource={
                  complete ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY) : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
                }
                disabled={!complete}
                textStroke={BUTTON_STROKES.PRIMARY}
                label={getText(TEXT_KEY.CONTINUE_BUTTON)}
                onClick={complete ? submitHandle : () => {}}
                containerStyle={styles.buttonContainer}
              />
            </Row>
          </Form>
        )}
      />
    );
  }
}

DefalutPaymentInfo.contextType = ThemeContext;

const MessageHolder = styled(Row)`
  justify-content: center;
  color: red;
`;

const TopTowContainer = styled(Row)`
  justify-content: center;
  margin-top: 1.5em;
`;

const RowContainerWithLessSpace = styled(Row)`
  margin-top: -0.5em;
`;

const styles = {
  buttonContainer: {
    margin: '10px auto',
  },
};

