import { isDailyWheelModal } from '../../../api/ActionCommandsInterface/utils';
import { ACTION_COMMANDS_TYPE } from '../../../api/ActionCommandsInterface/constants/types';
export var getLoginStreakList = function getLoginStreakList(bonusInfo) {
  var _bonusInfo$LOGIN_STRE;
  var loginStreakList = bonusInfo === null || bonusInfo === void 0 || (_bonusInfo$LOGIN_STRE = bonusInfo.LOGIN_STREAK_BONUS_LIST) === null || _bonusInfo$LOGIN_STRE === void 0 ? void 0 : _bonusInfo$LOGIN_STRE.slice(0);
  var loginStreakFinal = {};
  var loginStreakFinalDayNum = 0;
  if (bonusInfo !== null && bonusInfo !== void 0 && bonusInfo.WHEEL_HERO) {
    var _loginStreakFinal;
    loginStreakFinal = loginStreakList && loginStreakList[(loginStreakList === null || loginStreakList === void 0 ? void 0 : loginStreakList.length) - 1];
    loginStreakFinalDayNum = (_loginStreakFinal = loginStreakFinal) === null || _loginStreakFinal === void 0 ? void 0 : _loginStreakFinal.day;
    loginStreakList === null || loginStreakList === void 0 || loginStreakList.shift();
  } else {
    var _loginStreakFinal2;
    loginStreakFinal = loginStreakList === null || loginStreakList === void 0 ? void 0 : loginStreakList.pop();
    loginStreakFinalDayNum = (_loginStreakFinal2 = loginStreakFinal) === null || _loginStreakFinal2 === void 0 ? void 0 : _loginStreakFinal2.day;
  }
  return {
    loginStreakList: loginStreakList,
    loginStreakFinal: loginStreakFinal,
    loginStreakFinalDayNum: loginStreakFinalDayNum
  };
};
export var lightAssets = function lightAssets(dailyWheelAssets) {
  return [dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_OFF, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_1, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_2, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_3, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_4, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_5, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_6, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_7, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_8, dailyWheelAssets.DAILY_SPIN_COLUMN_LIGHT_9];
};
export var SPINNER_ITEM_REWARD_TYPE = {
  REGULAR: 'regular',
  OUTSTANDING: 'outstanding'
};
export var checkIfDailyWheelAvailable = function checkIfDailyWheelAvailable(actions, loadinLayerDispatcher) {
  var actionsLength = actions === null || actions === void 0 ? void 0 : actions.length;
  if (!actionsLength) return;
  for (var i = 0; i < actionsLength; i++) {
    var _actions$i, _actions$i2;
    if (loadinLayerDispatcher && ((_actions$i = actions[i]) === null || _actions$i === void 0 ? void 0 : _actions$i.actionType) === ACTION_COMMANDS_TYPE.DISPLAY_MODAL && isDailyWheelModal((_actions$i2 = actions[i]) === null || _actions$i2 === void 0 || (_actions$i2 = _actions$i2.modal) === null || _actions$i2 === void 0 ? void 0 : _actions$i2.modalType)) {
      loadinLayerDispatcher(true);
      break;
    }
  }
};