import { getText, TEXT_KEY } from '../../../../utils/localization';
var formatter = require('format-number');
var generatePatchedText = function generatePatchedText(textKey) {
  var _getText, _getText2;
  var firstLetter;
  var patchedWord;
  firstLetter = (_getText = getText(textKey)) === null || _getText === void 0 || (_getText = _getText.charAt(0)) === null || _getText === void 0 ? void 0 : _getText.toUpperCase();
  patchedWord = firstLetter + ((_getText2 = getText(textKey)) === null || _getText2 === void 0 || (_getText2 = _getText2.slice(1)) === null || _getText2 === void 0 ? void 0 : _getText2.toLowerCase());
  return patchedWord;
};
export var format = formatter({
  prefix: '',
  suffix: '',
  truncate: 0
});
export var transformUnits = function transformUnits(value, unitName) {
  var units = [{
    name: 'Sec',
    divisorToNext: 60
  }, {
    name: 'Min',
    divisorToNext: 60
  }, {
    name: 'Hr',
    divisorToNext: 24
  }, {
    name: 'Day'
  }];
  var unitIndex = units.findIndex(function (unit) {
    return unit.name === unitName;
  });
  if (units[unitIndex].divisorToNext === undefined) {
    return {
      value: value,
      unit: unitName
    };
  }
  var newValue = value / units[unitIndex].divisorToNext;
  if (newValue < 1) {
    return {
      value: value,
      unit: unitName
    };
  }
  return transformUnits(newValue, units[unitIndex + 1].name);
};
export var getFullUnitName = function getFullUnitName(shortUnitName, value) {
  switch (shortUnitName) {
    case 'Sec':
      return generatePatchedText(TEXT_KEY.SECONDS);
    case 'Min':
      return value > 1 ? generatePatchedText(TEXT_KEY.MINUTES) : generatePatchedText(TEXT_KEY.MINUTE);
    case 'Hr':
      return value > 1 ? generatePatchedText(TEXT_KEY.HOURS) : generatePatchedText(TEXT_KEY.HOUR);
    case 'Day':
      return value > 1 ? generatePatchedText(TEXT_KEY.DAYS) : generatePatchedText(TEXT_KEY.DAY);
    default:
      return shortUnitName;
  }
};