import { css } from 'styled-components';

export const wrapperStyle = css`
  width: calc(100% + 4rem);
  left: -2rem;
`;

export const titleStyle = css`
  font-size: 3vmin;
`;

export const defaultHeaderContainerStyle = css`
  .form-title {
    @extend .gradientText;
    line-height: 1;
    font-size: 4vmin;
    font-weight: 600;
    margin: 0 0 0vmin 0 !important;

    position: relative;
    display: flex;
    justify-content: center;
  }
`;

export const headerHeightStyle = css`
  height: 2.5rem;
  margin-bottom: 1rem;
`;

