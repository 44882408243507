import styled from 'styled-components';
import theme from 'shared/assets/style/theme';

export const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const TermsFooterWrapper = styled.div`
  justify-content: space-between !important;
  height: 10% !important;
  margin-top: 3%;
`;

export const TermsItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 2rem 0;
`;

export const TermsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  margin: 1em auto;
  gap: 0.8rem;
  @media only screen and (max-width: 1400px) and (max-height: 800px) {
    gap: 0px;
  }
`;

export const TermsLinkText = styled.a`
  display: flex;
  flex: 1.1;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-start;
  font-size: 1.3rem;
  font-weight: 800;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-underline-offset: 8px;
  color: ${theme.palette.common[1]};
  &:hover {
    color: ${theme.palette.common[1]};
    ${({ theme }) => theme}
  }

  @media only screen and (max-width: 1400px) and (max-height: 800px) {
    font-size: 1.1rem;
  }

  @media only screen and (max-width: 1300px), only screen and (min-width: 1800px) {
    font-size: 1.6rem;
  }

  ${({ theme }) => theme}
`;

export const PrivacyLinkText = styled(TermsLinkText)`
  flex: 0.9;
  justify-content: flex-end;
`;

export const TermsLinkArrow = styled.img`
  height: 2.5rem;
  width: 2.5rem;
  transform: rotate(180deg);
  object-fit: contain;
  @media only screen and (max-width: 1400px) and (max-height: 800px) {
    height: 1.8rem;
    width: 1.8rem;
  }
`;

export const TermsUpdateScreenTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;

  @media only screen and (max-width: 1300px), only screen and (min-width: 1800px) {
    font-size: 1.2rem;
  }
`;

export const FooterAgreeTermsText = styled.p`
  font-weight: 300;
  font-size: 0.8rem;
  margin-left: 0.5rem !important;
  color: ${theme.palette.common[1]};
  cursor: pointer;

  @media only screen and (max-width: 1300px), only screen and (min-width: 1800px) {
    font-size: 1.2rem;
  }
`;

export const ErrorText = styled(TermsUpdateScreenTextWrapper)`
  text-align: center;
  color: red;
`;

