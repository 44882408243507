import generateRender from 'shared/utils/generateRender';
import DefaultVideoAdsHeader from '../../../../components/JSModal/components/DefaultHeader';
import HighlightedVideoAdsHeader from '../../../../components/JSModal/components/HighlightedHeader';

const ModalHeader = generateRender({
  default: DefaultVideoAdsHeader,
  millelacs: HighlightedVideoAdsHeader,
  jamul: HighlightedVideoAdsHeader,
  ladyluck: HighlightedVideoAdsHeader,
});

export default ModalHeader;

