import generateRender from 'shared/utils/generateRender';

const getTextstyle = (categoryName, categorySelected, categoryId) => {
  const defaultColor = categorySelected === categoryId ? '#7C028D' : '#2E074F';
  return `
    text-transform: uppercase;
    font-size: ${categoryName?.length > 15 ? '0.8rem' : '1rem'};
    width: ${categoryName?.length > 15 ? '99%' : '100%'};
    padding-bottom: ${categoryName?.length > 15 ? '0%' : '3%'};
    text-stroke: 1px ${defaultColor};
    -webkit-text-stroke: 1px ${defaultColor};

     @media (max-width: 960px) {
      font-size: ${categoryName?.length > 15 ? '0.8rem' : '0.9rem'};
    }

    @media (max-width: 859px) {
      text-stroke: none;
      -webkit-text-stroke: none;
      font-size: ${categoryName?.length > 15 ? '0.5rem' : '0.6rem'};
    }

    @media (max-height: 520px) {
      text-stroke: none;
      -webkit-text-stroke: none;
      font-size: ${categoryName?.length > 15 ? '0.5rem' : '0.6rem'};
    }
`;
};

const calculateHeight = length => {
  return Math.ceil(length / 3) * 60 + 60;
};

const calculateHeightSmallScreen = length => {
  return Math.ceil(length / 3) * 40 + 50;
};

const calculateHeightMediumScreen = length => {
  return Math.ceil(length / 3) * 50 + 60;
};

const categoriesGradientBorderColor = generateRender({
  default: `linear-gradient(to bottom, #ffcc73 0%, #fffffe 30%, #ffb00a 100%)`,
});

const categoriesGradientBackgroundColor = generateRender({
  default: `linear-gradient(to bottom, #1e1227, #431961)`,
  millelacs: `linear-gradient(to bottom, #006391, #013251)`,
  ladyluck: `linear-gradient(to bottom, #34003d, #a324aa)`,
  ti: `linear-gradient(to bottom, #0e1b20, #097aab)`,
});

export {
  getTextstyle,
  calculateHeight,
  calculateHeightSmallScreen,
  calculateHeightMediumScreen,
  categoriesGradientBorderColor,
  categoriesGradientBackgroundColor,
};

