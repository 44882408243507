import React, { Component } from 'react';
import SForgotPassword from 'shared/screens/ForgotPassword';
import ThemeContext from 'shared/context/ThemeContext';
import ForgetPasswordForm from './components/ForgetPasswordForm';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  handleInputChange = (event, field) => {
    this.setState({ [field]: event.value });
  };

  render() {
    const { email } = this.state;
    const themeContext = this.context;

    return (
      <SForgotPassword
        {...this.props}
        render={args => {
          const { handleSubmit, success, error, handleCancel, resetState } = args;
          return (
            <ForgetPasswordForm
              email={email}
              handleInputChange={this.handleInputChange}
              resetState={resetState}
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
              success={success}
              error={error}
              themeContext={themeContext}
            />
          );
        }}
      />
    );
  }
}

ForgotPassword.contextType = ThemeContext;

