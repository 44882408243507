import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import useThemeContext from 'shared/context/hooks/useThemeContext';
import generateRender from 'shared/utils/generateRender';

function ComingSoon({ children }) {
  const iosAppStoreLink = ENVIRONMENT_VARIABLES.APPLE_STORE_URL;
  const AndroidAppStoreLink = ENVIRONMENT_VARIABLES.ANDROID_STORE_URL;
  const { Start } = useThemeContext();

  return (
    <PageWrapper theme={isMobile ? Start.ComingSoonBackgroundsMobile : Start.ComingSoonBackgrounds}>
      <ContentContainer>
        <LinkContainer>
          <DownloadLinks iosAppStoreLink={iosAppStoreLink} AndroidAppStoreLink={AndroidAppStoreLink} />
          {children}
        </LinkContainer>
      </ContentContainer>
    </PageWrapper>
  );
}

export default ComingSoon;

const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-image: url(${assetSource(ASSET_KEY.COMING_SOON_TEXT)}), url(${assetSource(ASSET_KEY.SPLASH_BG)});
  background-size: 75%, cover;
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;

  @media screen and (max-width: 700px) {
    background-size: 100%, cover;
    background-position:
      center 45%,
      50%;
    ${({ theme }) => theme}
  }

  ${({ theme }) => theme}
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin-top: 30%;

  a {
    width: 20vw;
    height: 5vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    img {
      width: 100%;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 700px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 75%;
    margin-top: 65%;
    a {
      width: 75%;
      margin-top: 30px;
      height: 8vh;

      img {
        width: 100%;
        cursor: pointer;
      }
    }
  }
`;

const AllDownloadLinks = ({ iosAppStoreLink, AndroidAppStoreLink }) => {
  return (
    <>
      <a href={iosAppStoreLink}>
        <img src={assetSource(ASSET_KEY.BADGE_I_OS)} />
      </a>
      <a href={AndroidAppStoreLink}>
        <img src={assetSource(ASSET_KEY.BADGE_ANDROID)} />
      </a>
    </>
  );
};

const AndroidDownloadLink = ({ AndroidAppStoreLink }) => {
  return (
    <a href={AndroidAppStoreLink}>
      <img src={assetSource(ASSET_KEY.BADGE_ANDROID)} />
    </a>
  );
};

const DownloadLinks = generateRender({
  default: AllDownloadLinks,
  ladyluck: AndroidDownloadLink,
});

