import generateRender from 'shared/utils/generateRender';
import DefaultFormContainer from './DefaultFormContainer';
import BoxyLayoutFormContainer from './BoxyLayoutFormContainer';

const FormContainer = generateRender({
  default: DefaultFormContainer,
  ladyluck: BoxyLayoutFormContainer,
});

export default FormContainer;

