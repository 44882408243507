export var filterCustomProperties = function filterCustomProperties(userProperty) {
  return function (property) {
    return property.showProperty && property.propertyId === Number(userProperty);
  };
};
export var parseInitPropertyResponse = function parseInitPropertyResponse(initPropertyResponse, assetBaseUrl) {
  var parsedResponse = {};
  if (initPropertyResponse) {
    var highRollericonsList = initPropertyResponse.highLimitPopup.map(function (item) {
      return {
        icon: assetBaseUrl + '/' + item.icon
      };
    });
    parsedResponse.enterRequirements = highRollericonsList;
    parsedResponse.loyaltyCardComingSoon = !!initPropertyResponse.loyaltyCardComingSoon || false;
    if (initPropertyResponse.properties) parsedResponse.propertySelector = initPropertyResponse.properties;
    if (initPropertyResponse.cardLinkingPopup) parsedResponse.cardLinkPopupImage = initPropertyResponse.cardLinkingPopup.image;
    if (initPropertyResponse.playerPropertyId) parsedResponse.userProperty = Number(initPropertyResponse.playerPropertyId);
    if (initPropertyResponse.dailyWheelIcon) parsedResponse.dailyWheelCard = initPropertyResponse.dailyWheelIcon;
    if (initPropertyResponse.cardIcon) parsedResponse.linkCards = initPropertyResponse.cardIcon;
    if (initPropertyResponse.texts) parsedResponse.texts = initPropertyResponse.texts;
    parsedResponse.enableOrderCard = initPropertyResponse.enableOrderCard;
    parsedResponse.showLinkLoyaltyCardSetting = initPropertyResponse.showLinkLoyaltyCardSetting;
    parsedResponse.isTermsUpdated = initPropertyResponse.isTermsUpdated;
    parsedResponse.defaultSignupFormSettings = initPropertyResponse.defaultSignupFormSettings;
    parsedResponse.playerPropertyLoyaltyCardType = initPropertyResponse === null || initPropertyResponse === void 0 ? void 0 : initPropertyResponse.playerPropertyLoyaltyCardType;
  }
  return parsedResponse;
};