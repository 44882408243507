export var BOOLEANS = {
  TRUE: 'true',
  FALSE: 'false',
  YES: 'yes',
  NO: 'no'
};
export var LOGIN_METHOD = {
  SIGNIN: 'SignInMethod',
  GUEST: 'Guest',
  BBEGuest: 'BBEGuest',
  MEMBER: 'Member'
};
export var SCALAR_TYPE = {
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  STRING: 'string'
};
export var CARD_VALUES = {
  VISA: 'MY VISA',
  MASTERCARD: 'MY MASTERCARD',
  AMEX: 'MY AMEX'
};
export var CARD_TYPE = {
  VISA: 'visa',
  MASTERCARD: 'mastercard'
};
export var TARGET_PROPERTY = 'target';
export var DIMENSIONS = {
  SCREEN: 'screen',
  WINDOW: 'window'
};
export var IS_EMBEDDED_TYPE = {
  YES: 'yes',
  NO: 'no'
};
export var PENDING_MESSAGE = {
  BONUS_LINK: 'BONUS_LINK',
  PROMO_LINK: 'PROMO_LINK'
};
export var TYPEOF = {
  FUNCTION: 'function',
  OBJECT: 'object',
  NUMBER: 'number'
};
export var PURCHASE_RESPONSE = {
  SUCCESS: 'success',
  ERROR: 'error'
};
export var PURCHASE_TYPE = {
  COINS: 'coins',
  VIP: 'vip',
  VIP_ROOM_ACCESS: 'vip_room_access'
};
export var PLATFORM = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web'
};
export var TERMS_TYPE = {
  TERMS: 'TERMS',
  POLICY: 'POLICY'
};
export var EVENT_EMITTER_TYPE = {
  ACTION_COMMANDS: 'ACTION_COMMANDS'
};
export var PROMO_BOOST_DIALOG_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error'
};
export var REQUEST_QUERY_STATUS = {
  UNINITIALIZED: 'uninitialized',
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected'
};
export var LAYOUT = {
  BOTTOM_BAR: 'bottom-bar',
  NAVIGATION_BAR: 'navigation-bar'
};
export var BOTTOM_BAR_LAYOUT = {
  DEFAULT: 'default',
  VERTICAL: 'vertical'
};
export var NAVIGATION_BAR_LAYOUT = {
  DEFAULT: 'default',
  INVERTED: 'inverted'
};
export var SYSTEM_OS = {
  WINDOWS: 'Windows',
  MACOS: 'MacOS',
  LINUX: 'Linux',
  ANDROID: 'Android',
  IOS: 'iOS'
};