import styled, { css } from 'styled-components';
import theme from 'shared/assets/style/theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 600px) {
    width: auto;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 600px) {
    width: auto;
  }
`;

export const TopText = styled.p`
  text-align: center;
  margin: 20px 0px !important;
  line-height: 22px !important;
  @media only screen and (max-width: 600px) {
    margin: -15% 0px 25px 0px !important;
  }
`;

export const BoxyLayoutTopText = styled(TopText)`
  @media only screen and (min-width: 601px) and (max-width: 1000px) and (orientation: landscape) {
    margin: -8% 0px -0.5rem 0px !important;
  }
`;

export const MessageTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;

  @media only screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const MessageText = styled.p`
  margin: 0px;
  line-height: 22px !important;
  text-align: center;
  color: ${({ error }) => (error ? 'red' : 'green')};
  @media only screen and (max-width: 600px) {
    width: 98%;
    font-size: 0.9rem;
  }
  @media only screen and (min-width: 601px) and (max-width: 1000px) and (orientation: landscape) {
    font-size: 0.9rem;
  }
`;

export const FormWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: 601px) and (max-width: 1000px) and (orientation: landscape) {
    width: 100%;
    flex-direction: row;
  }
`;

export const BoxyFormWrapper = styled(FormWrapper)`
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const FormInputLabel = styled.label`
  width: 100%;
  text-align: left;
  margin-bottom: 0.5em;
  @media only screen and (min-width: 601px) and (max-width: 1000px) and (orientation: landscape) {
    margin-bottom: 0;
    margin-left: 5px;
  }
`;

export const BoxyLayoutFormInputWrapper = styled(FormInputLabel)`
  line-height: 0;
  margin-bottom: 1rem;
  @media only screen and (min-width: 601px) and (max-width: 1000px) and (orientation: landscape) {
    margin: 1rem 0.3rem;
  }
`;

export const InputHeader = styled.h5`
  margin: 0px !important;
  color: ${theme.palette.common[29]};
  font-size: 1.1rem;
  font-weight: 400;
  ${({ theme }) => theme}
`;

export const FormInput = styled.input`
  width: 100%;
  height: 46px;
  text-align: left;
  box-shadow: none;
  border-radius: 0.3em;
  color: white;
  padding-left: 5px;
  line-height: 2px;
  ${({ theme }) => theme}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  cursor: pointer;
  pointer-events: auto;
  width: 95%;
  margin-top: 5vh;
  margin-bottom: 5vh;
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-top: 0vh;
    margin-bottom: 0vh;
  }

  @media only screen and (min-width: 601px) and (max-width: 1000px) and (orientation: landscape) {
    margin-top: 0vh;
    margin-bottom: 12vh;
  }
`;

export const WideButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  cursor: pointer;
  pointer-events: auto;
  width: 95%;
  margin-top: 5vh;
  margin-bottom: 5vh;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    margin-top: 0vh;
    margin-bottom: 0vh;
  }

  @media only screen and (min-width: 601px) and (max-width: 1000px) and (orientation: landscape) {
    flex-direction: row;
    margin-top: 0vh;
    margin-bottom: 12vh;
  }
`;

export const SingleButtonWrapper = styled(WideButtonsWrapper)`
  justify-content: center;
  margin-top: 3vh;
`;

export const MessageContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  width: 100%;
`;

export const ErrorButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PeekerBoxyStyle = styled.div`
  position: absolute;
  height: 42px;
  width: 30px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 16px;
  z-index: 2;
  cursor: pointer;
  @media only screen and (max-width: 600px),
    only screen and (min-width: 601px) and (max-width: 1000px) and (orientation: landscape) {
    bottom: 50%;
    transform: translateY(50%);
  }
`;

export const iconTheme = css`
  @media only screen and (max-width: 600px),
    only screen and (min-width: 601px) and (max-width: 1000px) and (orientation: landscape) {
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const InputPasswordPeeker = styled.img`
  max-width: 1.2rem;
`;

export const styles = {
  buttonImageStyle: {
    width: 160,
    cursor: 'pointer',
  },
  titleStyle: {
    fontSize: '24px',
    fontWeight: '600',
    color: theme.palette.common[1],
    textTransform: 'uppercase',
  },
  modalContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    height: window.innerHeight < 700 ? '64vh' : '54vh',
    width: '100%',
    marginTop: '-1vw',
    marginBottom: '-1vw',
  },
  modalStyle: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: window.innerWidth < 1400 ? '0 40vmin' : window.innerWidth < 1800 ? '0 60vmin' : '0 70vmin',
  },
  wideButtonStyle: {
    width: window.innerHeight < 500 && window.innerWidth < 1000 ? '15rem' : '20rem',
    height: window.innerHeight < 500 && window.innerWidth < 1000 ? '2.5rem' : '3.5rem',
  },
};

