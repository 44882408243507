import DefaultFormButton from './DefaultFormButton';
import TiFormButton from './TiFormButton';
import MillleacsFormButton from './MillleacsFormButton';
import generateRender from 'shared/utils/generateRender';

const FormButton = generateRender({
  default: DefaultFormButton,
  ti: TiFormButton,
  millelacs: MillleacsFormButton,
  jamul: TiFormButton,
  ladyluck: TiFormButton,
});

export default FormButton;

