import { useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import querystring from 'query-string';
import { getText, TEXT_KEY } from '../../../../utils/localization';
import Utils from '../../../../utils/platform';
var useLinkMemberAccount = function useLinkMemberAccount(_ref) {
  var submitHandler = _ref.submitHandler;
  useEffect(function () {}, []);
  var onSumbitHandler = function onSumbitHandler(_ref2) {
    var email = _ref2.email,
      password = _ref2.password;
    if (email && password) submitHandler(email, password)();
  };
  var formikValidation = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSumbitHandler,
    validateOnMount: false
  });
  var values = formikValidation.values,
    isValid = formikValidation.isValid;
  var isValidToSubmit = isValid && values.email !== '' && values.password !== '';
  return {
    formikValidation: formikValidation,
    isValidToSubmit: isValidToSubmit
  };
};
export default useLinkMemberAccount;
var isWeb = Utils.getPlatform() === 'web';
var validationSchema = yup.object({
  email: yup.string().email(isWeb ? function () {
    return getText(TEXT_KEY.INVALID_EMAIL_FORMAT);
  } : getText(TEXT_KEY.INVALID_EMAIL_FORMAT)).required(isWeb ? function () {
    return getText(TEXT_KEY.LOGIN_EMAIL_REQUIRED);
  } : getText(TEXT_KEY.LOGIN_EMAIL_REQUIRED)),
  password: yup.string().min(6, isWeb ? function () {
    return getText(TEXT_KEY.PASSWORD_LENGTH);
  } : getText(TEXT_KEY.PASSWORD_LENGTH)).required(isWeb ? function () {
    return getText(TEXT_KEY.LOGIN_PASSWORD_REQUIRED);
  } : getText(TEXT_KEY.LOGIN_PASSWORD_REQUIRED))
});
var initialValues = {
  email: '',
  password: ''
};