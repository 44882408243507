import styled, { keyframes, css } from 'styled-components';

const flicker = keyframes`
   0% { opacity: 1; }
  20% { opacity: 1; }
  25% { opacity: 0.5; }
  75% { opacity: 0.5; }
  80% { opacity: 1; }
  100% { opacity: 1; }
`;

export const StyledFlickerText = styled.div`
  font-feature-settings: 'tnum';
  ${({ isTimeInDanger }) =>
    isTimeInDanger &&
    css`
      animation: ${flicker} ${({ flickeringEffectSpeed }) => (flickeringEffectSpeed * 2) / 1000}s linear infinite;
    `}
`;

export const StyledTimerText = styled.div`
  font-feature-settings: 'tnum';
`;

