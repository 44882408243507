import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ERROR_EXCEPTION } from '../../constants';
import { EVENT_TYPES } from '../../lib/analytics/constants';
import Logger from '../../lib/analytics/logger';
import { AUTH_TYPES } from '../../utils/authFlow/constants';
import ERROR_CODE from '../../utils/errorCodes';
import { getActiveRouteName } from '../../utils/navigation';
import navigation from '../../utils/navigation/navigation';
import { HTTP_STATUS } from '../api/services/utils/constants';
var sendMetrics = function sendMetrics(body) {
  return Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, body);
};
var handleLoggerErrorEvents = function handleLoggerErrorEvents(error) {
  var isValidationError = error.name && error.name.includes(ERROR_EXCEPTION.VALIDATION_ERROR);
  sendMetrics({
    error: isValidationError ? error.cause : error.message,
    code: isValidationError ? ERROR_CODE.JSONSCHEMA_VALIDATION_FAIL : ERROR_CODE.QUERY_RESPONSE_ERROR
  });
};
export var handleQueryLoggerEvents = function handleQueryLoggerEvents(error) {
  var _error$error, _error$error2, _error$error3, _getState$modal;
  var getState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var url = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  if ((error === null || error === void 0 || (_error$error = error.error) === null || _error$error === void 0 ? void 0 : _error$error.status) !== HTTP_STATUS.NOT_FOUND) sendMetrics(_objectSpread(_objectSpread({
    error: JSON.stringify((error === null || error === void 0 || (_error$error2 = error.error) === null || _error$error2 === void 0 || (_error$error2 = _error$error2.data) === null || _error$error2 === void 0 ? void 0 : _error$error2.errorMessage) || (error === null || error === void 0 || (_error$error3 = error.error) === null || _error$error3 === void 0 ? void 0 : _error$error3.data)) || error.message,
    code: ERROR_CODE.QUERY_RESPONSE_ERROR,
    currentScreen: getActiveRouteName(navigation.getCurrentRoute())
  }, getState && {
    openedModal: (_getState$modal = getState().modal) === null || _getState$modal === void 0 ? void 0 : _getState$modal.modal
  }), url && {
    url: url
  }));
};
export var handleAuthenticationErrorEvents = function handleAuthenticationErrorEvents(data, authType) {
  return sendMetrics({
    error: JSON.stringify(data, Object.getOwnPropertyNames(data)),
    code: errorCodeByAuthType[authType]
  });
};
var errorCodeByAuthType = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, AUTH_TYPES.STANDARD, ERROR_CODE.LOGIN_INTERFACE_LOGIN_CATCH), AUTH_TYPES.GUEST, ERROR_CODE.LOGIN_INTERFACE_GUEST_LOGIN_CATCH), AUTH_TYPES.APPLE, ERROR_CODE.LOGIN_INTERFACE_APPLE_LOGIN_CATCH), AUTH_TYPES.FACEBOOK, ERROR_CODE.LOGIN_INTERFACE_FB_LOGIN_CATCH), AUTH_TYPES.GOOGLE, ERROR_CODE.LOGIN_INTERFACE_GOOGLE_LOGIN_CATCH), AUTH_TYPES.SIGNUP, ERROR_CODE.SIGNUP_ERROR);
export default handleLoggerErrorEvents;