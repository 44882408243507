import React from 'react';
import ComingSoon from './components/ComingSoon';
import SStart from 'shared/screens/Start';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import TermsModalWrapper from '../../components/TermsModal/TermsModalComponents/TermsModalWrapper';
import ResetPassword from '../ResetPassword';

function ComingSoonLayout({ navigation }) {
  return (
    <ComingSoon>
      <SStart
        navigation={navigation}
        render={args => {
          const { closeModal, recoverPasswordResponse } = args;
          return (
            <ResetPassword
              isVisible={recoverPasswordResponse.visible}
              closeModal={closeModal}
              zIndex={101}
              navigation={navigation}
              invalid={recoverPasswordResponse.invalid}
              errorMessage={recoverPasswordResponse.message}
              recoverId={recoverPasswordResponse.id}
              recoverToken={recoverPasswordResponse.token}
              getTextFunc={getText}
              textKeys={TEXT_KEY}
              TermsModalWrapper={TermsModalWrapper}
            />
          );
        }}
      />
    </ComingSoon>
  );
}

export default ComingSoonLayout;

