import React, { useEffect } from 'react';
import { useSelector } from 'shared/node_modules/react-redux';
import SweepstakerInfo from './components/InfoScreen/EventsInfo';
import styled from 'styled-components';
import useEvents from 'shared/screens/Events/hooks/useEvents';
import useCheckEventsQueryParam from 'shared/screens/Events/hooks/useCheckEventsQueryParam';
import EventsCard from './components/Cards';
import QueryParamsProxy from 'shared/utils/queryParamProxy';
import Screens from 'shared/screens';
import CustomScrollBar from '../../components/CustomScrollBar';
import './scrollBarStyles.scss';

const Events = ({ navigation }) => {
  const { eventsFeatureState } = useEvents();
  const { events, texts } = eventsFeatureState;
  const { selectedCardIndex } = useCheckEventsQueryParam(events);

  useEffect(() => {
    if (events.length === 0) QueryParamsProxy.navigate(navigation, Screens.Home);
  }, []);

  let eventsAssets = useSelector(state => state.assetsPackage.assets.EVENTS_FEATURE);

  return eventsAssets ? (
    <Container>
      <SweepstakerInfo eventsAssets={eventsAssets} eventsTexts={texts} />
      <Background src={eventsAssets.EVENTS_BACKGROUND} />
      <EventsContainer>
        <CustomScrollBar
          isDraggableX={true}
          customHeight="0"
          showHorizontal={true}
          showVertical={false}
          customStyle={styles.CustomScrollBarContainerStyle}
          customHorizontalTrackStyle={styles.customHorizontalTrackStyle}
          customClassNames="custom-scrollbar-events"
        >
          {events?.map((item, index) => (
            <EventsCard
              defaultOpen={selectedCardIndex === index}
              object={item}
              eventsAssets={eventsAssets}
              texts={texts}
            />
          ))}
        </CustomScrollBar>
      </EventsContainer>
    </Container>
  ) : (
    <></>
  );
};

export default Events;

const Container = styled.div`
  height: 100%;
  align-items: center;
  width: 100vw;
`;

const Background = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
`;

const EventsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 99%;
  overflow: hidden;
  white-space: nowrap;
  &:first-of-type {
    .sc-liaBrn {
      padding-right: 25vh;
      @media only screen and (min-height: 950px) and (max-height: 1100px) {
        gap: 15%;
      }

      @media only screen and (max-height: 800px) {
        padding-right: 30vh;
      }
    }
  }
`;

const styles = {
  customHorizontalTrackStyle: {
    position: 'fixed !important',
    bottom: '10px !important',
  },
  CustomScrollBarContainerStyle: {
    flexDirection: 'row',
    gap: '7%',
    display: 'flex',
    paddingTop: '12vh',
    paddingLeft: '3rem',
    marginLeft: '5rem',
  },
};

