import { SYSTEM_OS } from '../constants';
export default {
  getPlatform: function getPlatform() {
    return 'web';
  },
  getSystemOs: function getSystemOs() {
    var userAgent = window.navigator.userAgent;
    if (userAgent.includes('Windows')) return SYSTEM_OS.WINDOWS;
    if (userAgent.includes('Macintosh') || userAgent.includes('Mac OS X')) return SYSTEM_OS.MACOS;
    if (userAgent.includes('Linux')) return SYSTEM_OS.LINUX;
    if (userAgent.includes('Android')) return SYSTEM_OS.ANDROID;
    if (userAgent.includes('iPhone') || userAgent.includes('iPad')) return SYSTEM_OS.IOS;
    return 'Unknown';
  }
};