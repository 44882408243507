import React from 'react';
import styled from 'styled-components';
import Button from '../../../../components/Button/Button';
import {
  getTextstyle,
  calculateHeight,
  calculateHeightSmallScreen,
  calculateHeightMediumScreen,
  categoriesGradientBorderColor,
  categoriesGradientBackgroundColor,
} from './utils';
import BoxWithBorderGradient from '../../../../components/ContentBox/BoxWithGradientBorder';

const RewardCenterCategoriesOpened = props => {
  const {
    categories,
    rewardCenterAssets,
    setFilterData,
    setShowCategories,
    categorySelected,
    setCategorySelected,
    clearFilterData,
  } = props;

  const onClickButton = (categoryId, categoryName) => {
    if (categoryId === categorySelected) {
      clearFilterData();
      return;
    }
    setCategorySelected(categoryId);
    setFilterData(categoryName);
    setShowCategories(false);
  };

  return (
    <Container>
      <Background
        borderGradient={categoriesGradientBorderColor}
        borderRadius="30"
        borderWidth="3"
        length={categories.length}
      >
        <Header>
          <Arrow src={rewardCenterAssets.REWARD_CENTER_OPENED_MENU_ARROW} />
          <Title src={rewardCenterAssets.REWARD_CENTER_OPENED_MENU_TITLE} />
          <Arrow src={rewardCenterAssets.REWARD_CENTER_OPENED_MENU_ARROW} />
        </Header>
        <Buttons>
          {categories.map(({ categoryId, categoryName }) => (
            <Button
              key={categoryId}
              label={categoryName}
              imageSource={
                categorySelected === categoryId
                  ? rewardCenterAssets.REWARD_CENTER_OPENED_MENU_BUTTON_ACTIVE
                  : rewardCenterAssets.REWARD_CENTER_OPENED_MENU_BUTTON_DEFAULT
              }
              textStyle={getTextstyle(categoryName, categorySelected, categoryId)}
              imageStyle={buttonStyle}
              containerStyle={buttonContainerStyle}
              onClick={() => onClickButton(categoryId, categoryName)}
            />
          ))}
        </Buttons>
      </Background>
    </Container>
  );
};

export default RewardCenterCategoriesOpened;

const Background = styled(BoxWithBorderGradient)`
  position: absolute;
  width: 34rem;
  height: ${({ length }) => calculateHeight(length)}px;
  bottom: 0px;
  z-index: 2;
  background: ${categoriesGradientBackgroundColor};
  border-radius: 30px;

  @media only screen and (max-width: 859px), only screen and (max-height: 520px) {
    width: 22rem;
    border-radius: 20px;
    height: ${({ length }) => calculateHeightSmallScreen(length)}px;
    &:before {
      padding: 2px;
      border-radius: 20px;
    }
  }

  @media only screen and (min-width: 860px) and (max-width: 960px) and (min-height: 521px) {
    width: 30rem;
    border-radius: 25px;
    height: ${({ length }) => calculateHeightMediumScreen(length)}px;
    &:before {
      border-radius: 25px;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Header = styled.div`
  justify-content: space-around;
  display: flex;
  margin-top: 1rem;
`;

const Title = styled.img`
  width: 45%;
  height: 100%;
  object-fit: contain;
`;

const Arrow = styled.img`
  width: 3%;
  height: 100%;
  object-fit: contain;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5% 1.5%;
`;

const buttonContainerStyle = `
  width: 175px;
  margin-bottom: 5%;
  @media (max-width: 859px) {
    width: 112px;
  }
  @media only screen and (min-width: 860px) and (max-width: 960px) {
    width: 155px;
  }
  
  @media (max-height: 520px) {
    width: 112px;
  }
`;

const buttonStyle = `
  width: 155px;
  @media (max-width: 859px) {
    width: 92px;
  }
  @media only screen and (min-width: 860px) and (max-width: 960px) {
    width: 135px;
  }
    @media (max-height: 520px) {
    width: 92px;
  }
`;

