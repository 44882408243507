import ENVIRONMENT_VARIABLES from '../utils/environmentVariables';
var getApiRootValue = function getApiRootValue() {
  return ENVIRONMENT_VARIABLES.API_ROOT;
};
export var API_ROOT_LIST = getApiRootValue().split(',');
export function API_ROOT() {
  var apiRoot = getApiRootValue();
  var apiUrl = "https://".concat(apiRoot);
  return apiUrl;
}
export var Endpoints = {
  LOGIN: '/v2/login/',
  FORGOT_PASSWORD: '/v2/forgotpassword/',
  CHECK_IF_EMAIL_EXISTS: '/v2/checkIfEmailExists/',
  CREATE_USER: '/v2/createUser/',
  GUEST_LOGIN: '/v2/guestlogin/',
  GET_GAME_ROOMS: '/v2/getgamerooms',
  GET_FAV_GAMES: '/v2/getfavgames/',
  PROGRESSIVE_JACKPOTS: '/games/progressiveJackpots/',
  GET_PLAYER_PROFILE: '/v2/getplayerprofile/',
  UPDATE_PLAYER_PROFILE: '/v2/updatePlayerProfile/',
  INIT_APP: '/v2/initapp/',
  PROPERTIES: '/v2/initproperty/',
  UPDATE_APP_EVENT_INFO: '/v2/getCampaignData/',
  INIT_GAME: '/v2/initgame/',
  LEVEL_UP: '/v2/levelup/',
  GET_FB_APP_ID: '/v2/getFbAppId',
  GET_URLS: '/v2/getUrls',
  RESTART_SESSION: '/v2/restartsession/',
  GET_CURRENCY_PACKAGES: '/v2/getcurrpkgs/',
  GET_DYNAMIC_SALE: '/v2/getdynamicsales/',
  GET_DAILY_WHEEL_INFO: '/v2/getDailyWheelBonusData',
  CLAIM_DAILY_BONUS: '/v2/claimDailyBonus/',
  CLAIM_TIMED_BONUS: '/v2/claimTimedBonus/',
  GET_BILLING_PROFILES: '/v2/getbillingprofiles/',
  REMOVE_BILLING_PROFILE: '/v2/removebillingprofile/',
  REGISTER_REWARD_CARD: '/v2/registerloyaltycard',
  START_WEB_PURCHASE: '/v2/startwebpurchase/',
  FINISH_WEB_PURCHASE: '/v2/endwebpurchase/',
  FINISH_MOBILE_PURCHASE: '/v2/mobilepurchase/',
  GET_PURCHASE_ADDRESS: '/v2/getaddressforpurchase/',
  BILLING_PROFILE_PURCHASE: '/v2/purchaseagainstbillingprofile/',
  GET_ACTIVE_CONSUMABLES: '/v2/getActiveConsumables/',
  GET_SSO_LINKS: '/v2/getssolinks/',
  SSO_SIGNUP: '/v2/ssosignup/',
  SSO_LOGIN: '/v2/ssologin/',
  SSO_UPGRADE: '/v2/ssoUpgrade/',
  SSO_INIT_LINK_LOYALTY_CARD: '/v2/ssoinitlinkloyaltycard/',
  SSO_LINK_LOYALTY_CARD: '/v2/ssolinkloyaltycard/',
  LINK_LOYALTY_CARD: '/v2/linkLoyaltyCard',
  DE_DUPE_CHECK: '/v2/starInfoCheck/',
  KYC_CHECK: '/v2/kyccheck/',
  KYC_UPLOAD: '/v2/kycupload/',
  GET_INBOX_MESSAGES: '/v2/getinboxmessages/',
  GET_INBOX_MESSAGE_DETAILS: '/v2/getinboxmessagedetails/',
  DELETE_INBOX_MESSAGE: '/v2/deleteInboxMessage/',
  COLLECT_MESSAGE_REWARDS: '/v2/collectmessagerewards/',
  GET_LOBBY_ITEMS: '/v2/getLobbyItems',
  INIT_REWARDS: '/v2/initrewards/',
  GET_REWARD_DETAIL: '/v2/getrewarddetail/',
  GET_OFFER_HISTORY: '/v2/getPlayerOffersHistory/',
  PURCHASE_OFFER: '/v2/purchaseoffer/',
  GET_PLAYER_OFFER_DETAILS: '/v2/getplayerofferdetails/',
  SEND_OFFER_EMAIL: '/v2/sendofferemail/',
  GET_CASINO_DOLLAR_BALANCE: '/v2/getCasinoDollarBalance/',
  PURCHASE_WITH_CASINO_DOLLARS: '/v2/purchaseWithCasinoDollars/',
  ADD_FAVOURITE: '/v2/addfavgame/',
  REMOVE_FAVOURITE: '/v2/removefavgame/',
  SUBMIT_BONUSABLE_LINK: '/v2/submitbonusablelink/',
  // Watch and Earn (VideoAds)
  WATCH_AND_EARN_START: '/v2/watchAndEarnStart/',
  WATCH_AND_EARN_COMPLETE: '/v2/watchAndEarnComplete/',
  // Version Control
  VERSION_CONTROL: '/v2/getMobileVersionConfigs/',
  // Sound Settings
  TOGGLE_SOUND: '/v2/toggleSound/',
  TOGGLE_MUSIC: '/v2/toggleMusic/',
  CHANGE_PASSWORD: '/v2/changeplayerpassword',
  RECOVER_PASSWORD_TOKEN_CHECK: '/v2/checkpasswordtoken',
  RECOVER_PASSWORD: '/v2/updatepassword',
  // Web TrackingURL Data
  TRACKING_URL: '/v2/getaffiliatelandingpage/',
  COLLECT_WELCOME_BONUS: '/v2/collectwelcomebonus/',
  TERMS_AND_CONDITIONS: '/v2/getTermsAndConditionsUrl',
  ACCEPT_NEW_TERMS: '/v2/acceptTerms',
  DELETE_PLAYER_ACCOUNT: '/v2/deleteAccount',
  MISSIONS: '/missions',
  ASSETS: '/assets',
  GEO_CHECK: '/v2/geocheck',
  LINK_GUEST_ACCOUNT_EMAIL: '/v2/guestlinkemail',
  LINK_GUEST_ACCOUNT_GOOGLE: '/v2/guestLinkGoogleAccount',
  LINK_GUEST_ACCOUNT_APPLE: '/v2/guestLinkAppleAccount',
  LINK_GUEST_ACCOUNT_FACEBOOK: '/v2/guestLinkFacebookAccount',
  SEND_OTP_EMAIL_REQUEST: '/v2/otpSendEmail',
  RESEND_OTP_EMAIL_REQUEST: '/v2/otpResendEmail',
  AUTHENTICATE_OTP: '/v2/otpAuthenticate',
  TEXTS: '/v2/texts',
  ACTION_COMMAND_NOTIFICATION: '/v2/togglenotifications',
  ACTION_COMMAND_MARKETING_EMAIL: '/v2/togglemarketingemails',
  ACTION_COMMAND_TRIGGER: 'triggers/notify',
  EVENTS: '/events',
  FRIENDS_INVITE: '/friends/invites',
  SET_LANGUAGE: '/v2/setLanguage',
  REWARD_CENTER_DISPLAYED: '/v2/rewardsdisplayed'
};
export var ExternalEndpoints = {
  CUSTOMER_SUPPORT_BASE_URL: "https://".concat(ENVIRONMENT_VARIABLES.HELP_LINK),
  CUSTOMER_SUPPORT_ENDPOINT: '/api/v2/requests.json'
};
export var ApiTrackingWhitelistEndpoints = [Endpoints.LOGIN, Endpoints.GUEST_LOGIN, Endpoints.RESTART_SESSION, Endpoints.FINISH_MOBILE_PURCHASE];
export var RestartSessionBlacklistEndpoints = [Endpoints.RESTART_SESSION, Endpoints.GUEST_LOGIN, Endpoints.LOGIN];
export var InitAppResponseKeys = {
  DEV_ACCOUNT: 'dev',
  TUTORIAL: 'tut',
  TUTORIAL_ID: 'tutgid',
  ENABLE_FTUE: 'enableFTUE',
  SETTINGS: 'settings',
  WELCOME: 'welcomeItems',
  XP: 'xp',
  TIMED_BONUS: 'timedBonus',
  WATCH_AND_EARN: 'wae',
  INVENTORY: 'in',
  FAVOURITE: 'favs',
  CASINO_ID: 'casinoId',
  OUT_OF_COINS: 'outOfCoins',
  CASINO_ID_STATUS: 'cs',
  LOBBY: 'lobby',
  CURRENT_MEMBER_LEVEL: 'currentMemberLevel',
  PROMOTIONS: 'promoGrid',
  PROMOTIONS_SECOND: 'promoGrid2',
  PROMOTIONS_THIRD: 'promoGrid3',
  LIMITED_TIME_OFFER: 'lto',
  CACHE_ID: 'cacheId',
  PAYMENT_PROVIDER: 'paymentProvider',
  MAX_DOWNLOADED_GAMES: 'maxDownloadedGames',
  APPSFLYER_GAMES_OPENED: 'appsflyerGamesOpened',
  SNEAK_PEEK: 'sneakPeek',
  PRELOAD_ASSETS_PACKAGE_IDS: 'preloadAssetPackageIds',
  SALE: 'sale',
  LOBBY_BANNERS: 'lobbyBanners'
};
export var UpdateAppEventResponseKeys = {
  SALE: 'sale',
  MESSAGES: 'messages',
  WELCOME: 'welcomeItems',
  LIMITED_TIME_OFFER: 'limited_time_offer',
  OUT_OF_COINS: 'out_of_coins',
  PROMOTIONS: 'promo_grid',
  PROMOTIONS_SECOND: 'promo_grid2',
  PROMOTIONS_THIRD: 'promo_grid3',
  TIME_BONUS_BOOST: 'timed_bonus_boost',
  TIMED_BONUS: 'timed_bonus',
  XP_BOOST_MULTIPLIER: 'xp_boost_multiplier',
  LOBBY_BACKGROUND: 'lobby_background'
};
export var DailyBonusResponseKeys = {
  /** @type {SpinnerItemKeys[]} The items on the wheel */
  SPINNER_ITEMS: 'spinner_items',
  /** @type {Number} Which item from the spinner items the user won */
  PRIZE_INDEX: 'prize_index',
  /** @type {Number} Bonus amount from login streak */
  LOGIN_STREAK_BONUS: 'login_streak_bonus',
  /** @type {Number} Multiplier conferred by user's login streak */
  LOGIN_STREAK_COINS_LIST: 'login_streak_coins_list',
  /** @type {LoginStreakInfo} */
  LOGIN_STREAK_LOYALTY_LIST: 'login_streak_loyalty_list',
  /** @type {String} Which currency does the user receive for daily bonus */
  DAILY_BONUS_CURRENCY: 'daily_bonus_currency',
  /** @type {Number} User is on this day in their daily rewards. */
  CURRENT_REWARD_DAY: 'current_reward_day',
  /** @type {Object} member level tiers */
  MEMBER_TIERS: 'member_tiers',
  /** @type {String} player's member tier */
  CURRENT_MEMBER_TIER: 'player_tier',
  ASSET_PACKAGE: 'assetsPackageId',
  WHEEL_HERO: 'wheel_hero',
  CURRENT_REWARD_DAY_INDEX: 'current_reward_day_index',
  //New Items
  DISPLAY_TOTAL_LOYALTY: 'display_total_loyalty',
  DISPLAY_TOTAL_COINS_AFTER_TIER_BONUS: 'display_total_coins_after_tier_bonus',
  DISPLAY_WHEEL_BONUS: 'display_summary_wheel_reward',
  DISPLAY_DAY_STREAK_COINS: 'display_summary_streak_coins',
  ENABLE_LOYALTY: 'enable_loyalty_reward'
};
export var TimedBonusKeys = {
  /** @type {Number} time until new timed bonus */
  TIME_LEFT: 'timeLeft',
  /** @type {Number} current time interval */
  CURRENT_INTERVAL: 'timeInterval',
  /** @type {Number} amount of reward */
  AMOUNT: 'rewardAmount',
  /** @type {String} type of reward */
  TYPE: 'rewardCurrency'
};
export var SpinnerItemKeys = {
  /** @type {String} Currency type */
  CURRENCY: '0',
  /** @type {Number} How much */
  AMOUNT: '1',
  /** @type {Number} Order to display in */
  ORDER: '2',
  /** @type {String} Reward Type */
  REWARD: '3',
  /** @type {String} How much to Display */
  DISPLAY_AMOUNT: '4'
};
export var XPResponseKeys = {
  /** @type {Number} */
  CURRENT_XP: '0',
  /** @type {Number} */
  XP_PER_SPIN: '1',
  /**
   * This * number of coins bet with .5 rounded up
   * @type {Number}
   */
  XP_BET_MULTIPLIER: '2',
  /**
   * Will be 0 if we need to level up.
   * @type {Number}
   */
  XP_TO_NEXT: '3',
  /** Max level will be this number - 1
   * @type {Number}
   */
  MAX_LEVEL: '4',
  LEVEL_META: '5'
};
export var FavouriteResponseKeys = {
  FAVOURITE_GAMES: 'favourites',
  FEATURED_GAMES: 'featured',
  MAX_FAV_SLOTS: 'maxFavSlots',
  UNLOCK_LEVEL: 'unlockLvl',
  MESSAGE: 'message',
  IS_EMBEDDED: 'is_embedded',
  EMBED_URL: 'embed_url',
  HAS_PROGRESSIVE_JACKPOTS: 'has_progressive_jackpots'
};
export var LoginResponseKeys = {
  AUTH_TOKEN: 0,
  PLAYER_ID: 1,
  PROPERTY_ID: 2,
  RETURN_TOKEN: 3,
  CASINO_ID: 4,
  EMAIL: 5
};
export var InitGameResponseKeys = {
  URL: 0,
  PROVIDER: 1,
  /** @type {BetInfo} */
  BET_INFO: 2,
  GAME_TYPE: 3,
  SESSION_ID: 4,
  PROVIDER_GAME_ID: 5,
  EMBED_DATA: 6
};
export var GameResponseKeys = {
  GAME_ID: 0,
  GAME_NAME: 1,
  PROVIDER: 2,
  UNLOCK_LEVEL: 3,
  UNLOCKED: 4,
  CORNER_TEXT: 5,
  IS_EMBEDDED: 6,
  EMBED_URL: 7,
  IS_HIDDEN: 8,
  SNEAK_PEEK_END_DATE: 9,
  HAS_PROGRESSIVE_JACKPOTS: 10
};
export var RestartResponseKeys = {
  AUTH_TOKEN: 0,
  RETURN_TOKEN: 1,
  PLAYER_ID: 2,
  PROPERTY_ID: 3,
  CASINO_ID: 6,
  EMAIL: 7
};
export var ServerResponseKeys = {
  CALL_RESPONSE: 0,
  LEVEL: 1,
  XP: 2,
  CURRENCY_BALANCES: 3,
  DYNAMIC_SALES: 5,
  PROGRESSION: 7,
  ACTIONS_COMANDS: 8
};
export var ServerResponseCurrencyKeys = {
  COINS: 0,
  PREMIUM: 1,
  LOYALTY: 2
};
export var CurrencyPackagesResponseKeys = {
  NEWS_LIST: '0',
  PACKAGE_LIST: '1',
  NEXT_BOOST_INFO: '2',
  PAYMENT_PROVIDER: '4'
};
export var CurrencyPackageNewsListItemKeys = {
  TITLE: '0',
  DESCRIPTION: '1',
  IMAGE: '2',
  SHOW_BUTTON: '3',
  ACTION: '4',
  BUTTON_TEXT: '5',
  LOBBY_NEWS_OVERLAY_IMAGE: '6',
  GAME_PROVIDER_OVERLAY_LOGO: '7'
};
export var CurrencyPackageItemKeys = {
  NAME: '0',
  CORNER_FLAG: '1',
  IMAGE: '2',
  PERCENT_BOOST: '3',
  ORIGINAL_PRICE: '4',
  PRICE: '5',
  AMOUNT: '6',
  ORIGINAL_AMOUNT: '7',
  // If this is a coin package, it's loyalty; or vice versa
  ALT_AMOUNT: '8',
  BONUS_LOYALTY_AMOUNT: '9',
  CURRENCY_ITEM_ID: '10',
  MOBILE_PRODUCT_IAP_ID: '11',
  ORDER: '12',
  OLD_MOBILE_SKU: '13',
  IS_SALE: '14',
  PROMO_BOOSTED_VALUE: '15',
  AMOUNT_INCLUDING_SALE_AND_PROMO: '17'
};
export var OOCPackageItemKeys = {
  NAME: '0',
  PRICE: '1',
  AMOUNT: '2',
  BONUS_LOYALTY_AMOUNT: '3',
  CURRENCY_ITEM_ID: '4',
  MOBILE_PRODUCT_IAP_ID_IOS: '5',
  MOBILE_PRODUCT_IAP_ID_ANDROID: '6'
};
export var LTOPackageItemKeys = {
  NAME: 'itemname',
  TIME_LEFT_IN_SEC: 'timeLeftInSeconds',
  PRICE: 'pricePoint',
  AMOUNT: 'curramount',
  BONUS_LOYALTY_AMOUNT: 'bonusloyalty',
  CURRENCY_ITEM_ID: 'cpilId',
  MOBILE_PRODUCT_IAP_ID_IOS: 'iosSKU',
  MOBILE_PRODUCT_IAP_ID_ANDROID: 'androidSKU'
};
export var SSOLinksResponseKeys = {
  ANALYTICS: 'analytics',
  ANALYTICS_URL: 'analyticsURL',
  LINK_REDIRECT_AUTH_PARAM: 'ssoLinkRedirectAuthParam',
  LINK_REDIRECT_URI: 'ssoLinkRedirectURI',
  LINK_URL: 'ssoLinkURL',
  LOGIN_REDIRECT_AUTH_PARAM: 'ssoLoginRedirectAuthParam',
  LOGIN_REDIRECT_URI: 'ssoLoginRedirectURI',
  LOGIN_URL: 'ssoLoginURL',
  SIGNUP_URL: 'ssoSignUpURL'
};
export var SSOLoginTokenType = {
  AUTH_CODE: 'authCode',
  SSO_TOKEN: 'ssoToken',
  REFRESH_TOKEN: 'refreshToken'
};
export var LevelUpResponseKeys = {
  /** @type {Number} */
  XP_TO_NEXT: '0',
  /** @type {LevelUpReward} */
  REWARD: '1',
  /** @type {LevelUpCampaignInfo} campaign info or something idk */
  CAMPAIGN_INFO: '2',
  /** @type {Number} The new amounts being gained when redeeming timed bonus */
  TIMED_BONUS_AMOUNT: '3',
  /** @type {Object[]} Ran out of coins, offer the player this package */
  OUT_OF_COINS_PKG_INFO: '5'
};