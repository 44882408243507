import React, { useEffect } from 'react';
import styled from 'styled-components';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import useLinkMemberAccount from 'shared/screens/Settings/Account/hooks/useLinkMemberAccount';
import useThemeContext from 'shared/context/hooks/useThemeContext';
import { trimInputString } from 'shared/utils/string';
import BoxyLayoutTextInputsWrapper from '../../../../../../../../components/TextInput/BoxyLayoutTextInputsWrapper';
import Button from '../../../../../../../../components/Button/Button';
import { TextInputBootstrap } from '../../../../../../../../components/TextInput';
import PasswordInput from '../../../../../../../../components/PasswordInput';

export default function BoxyLoginForm(props) {
  const { submitHandler, serverResponse, isLoading, queryResult } = props;
  const { formikValidation, isValidToSubmit } = useLinkMemberAccount({ queryResult, submitHandler });
  const themeContext = useThemeContext();
  const { values, errors, handleSubmit, handleBlur, handleChange, setFieldValue } = formikValidation;

  useEffect(() => {
    if ((errors.email || errors.password) && !queryResult.isUninitialized) queryResult.reset();
  }, [errors.email, errors.password, queryResult.isUninitialized]);

  return (
    <>
      <FormRowHolder>
        <FormInputWrapper>
          <BoxyLayoutTextInputsWrapper
            checkErrorLength
            icon={assetSource(ASSET_KEY.INPUT_EMAIL_ICON_REGULAR)}
            iconError={assetSource(ASSET_KEY.INPUT_EMAIL_ICON_ERROR)}
            error={errors?.email}
          >
            <TextInputBootstrap
              icon
              boxErrorLayout
              error={errors?.email}
              placeholder={getText(TEXT_KEY.EMAIL)}
              autoCapitalize="none"
              value={values.email}
              onChange={e => setFieldValue('email', trimInputString(e.target.value))}
              onBlur={handleBlur('email')}
            />
          </BoxyLayoutTextInputsWrapper>
        </FormInputWrapper>
        <FormInputWrapper>
          <BoxyLayoutTextInputsWrapper
            checkErrorLength
            icon={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_REGULAR)}
            iconError={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_ERROR)}
            error={errors?.password}
          >
            <PasswordInput
              icon
              boxErrorLayout
              error={errors?.password}
              placeholder={getText(TEXT_KEY.PASSWORD)}
              value={values.password}
              onChange={handleChange('password')}
              onBlur={handleBlur('password')}
              secureTextEntry={true}
              customClassName="transparentInput"
              customPeekerStyle={styles.peeker}
            />
          </BoxyLayoutTextInputsWrapper>
        </FormInputWrapper>
      </FormRowHolder>
      {serverResponse && <ServerResponse>{serverResponse}</ServerResponse>}
      <LoginButtonWrapper>
        <LoginButton
          textStyle={themeContext.LinkAccountForm.MemberLinkAccountLoginButton}
          isValidInput={!isLoading && isValidToSubmit}
          onClick={handleSubmit}
          disabled={isLoading || !isValidToSubmit}
          label={getText(TEXT_KEY.LOGIN)}
          containerStyle={styles.buttonContainerStyle}
          imageStyle={styles.buttonImageStyle}
        />
      </LoginButtonWrapper>
    </>
  );
}

const FormRowHolder = styled.div`
  display: flex;
  width: 100%;
`;

const FormInputWrapper = styled.div`
  display: flex;
  flex: 1;
  margin: 5px;
  flex-direction: column;
`;

const ServerResponse = styled.div`
  color: red;
  margin-top: 1em;
  text-align: center;
`;

const LoginButton = styled(Button).attrs(({ isValidInput }) => ({
  imageSource: isValidInput
    ? assetSource(ASSET_KEY.ROUNDEDRECT_PRIMARY_WIDE)
    : assetSource(ASSET_KEY.ROUNDEDRECT_DISABLED_WIDE),
}))`
  cursor: pointer;
  font-size: 1.7rem;
  font-weight: 600;
  width: 35vh;
  height: 6vh;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
`;

const LoginButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const styles = {
  peeker: {
    position: 'absolute',
    height: 42,
    width: 30,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: 16,
    zIndex: 2,
    cursor: 'pointer',
  },
  buttonImageStyle: {
    width: '35vh',
    height: '6vh',
  },
  buttonContainerStyle: {
    width: '35vh',
    height: '6vh',
    marginTop: '3%',
  },
};

