import generateRender from 'shared/utils/generateRender';
import DefaultForm from './DefaultLoginForm';
import BoxyLoginForm from './BoxyLoginForm';

const LoginForm = generateRender({
  default: DefaultForm,
  ladyluck: BoxyLoginForm,
});

export default LoginForm;
