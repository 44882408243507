export const DEFAULT_RESET_PASSWORD_TEXTS = {
  CHOOSE_PASSWORD: 'CHOOSE PASSWORD',
  SENDING_NEW_PASSWORD_REQUEST: '...Sending the change password request',
  INVALID_BODY_MESSAGE: 'Your new password must be at least 8 characters and a combination of number and letters',
  TYPE_NEW_PASSWORD_BELOW: 'Please type your new password below',
  PASSWORD_HAS_BEEN_RESET: 'Your password has been Reset! Please login with your new password!',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm password',
  THANKS: 'THANKS',
  SUBMIT: 'SUBMIT',
  CANCEL: 'CANCEL',
  OK: 'OK',
};

export const ADMIRAL_RESET_PASSWORD_TEXTS = {
  CHOOSE_PASSWORD: 'PASSWORT FESTLEGEN',
  SENDING_NEW_PASSWORD_REQUEST: '... Anfrage zur Änderung des Passworts wird gesendet.',
  INVALID_BODY_MESSAGE:
    'Dein neues Passwort muss aus mindestens 8 Zeichen bestehen und eine Kombination aus Ziffern und Buchstaben sein.',
  TYPE_NEW_PASSWORD_BELOW: 'Bitte gib unten dein neues Passwort ein.',
  PASSWORD_HAS_BEEN_RESET: 'Dein Passwort wurde zurückgesetzt! Bitte melde dich mit deinem neuen Passwort an!',
  NEW_PASSWORD: 'Neues Passwort',
  CONFIRM_PASSWORD: 'Passwort bestätigen',
  THANKS: 'DANKE',
  SUBMIT: 'ABSENDEN',
  CANCEL: 'ABBRECHEN',
  OK: 'OK',
};

export const ADMES_RESET_PASSWORD_TEXTS = {
  CHOOSE_PASSWORD: 'ELEGIR CONTRASEÑA',
  SENDING_NEW_PASSWORD_REQUEST: 'Enviando la solicitud de cambio de contraseña...',
  INVALID_BODY_MESSAGE:
    'Tu nueva contraseña debe tener al menos 8 caracteres y ser una combinación de números y letras.',
  TYPE_NEW_PASSWORD_BELOW: 'Escribe tu nueva contraseña debajo',
  PASSWORD_HAS_BEEN_RESET: '¡Se ha restablecido tu contraseña! Inicia sesión con tu contraseña nueva.',
  NEW_PASSWORD: 'Contraseña nueva',
  CONFIRM_PASSWORD: 'Confirmar contraseña',
  THANKS: 'GRACIAS',
  SUBMIT: 'ENVIAR',
  CANCEL: 'CANCELAR',
  OK: 'VALE',
};

export const RESET_PASS_ACTIONS = {
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  SENDING_DATA: 'SENDING_DATA',
  INVALID_BODY: 'INVALID_BODY',
  SUCCESS_RESPONSE: 'SUCCESS_RESPONSE',
  ERROR_RESPONSE: 'ERROR_RESPONSE',
  TOGGLE_PASSWORD: 'TOGGLE_PASSWORD',
};

