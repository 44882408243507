import { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'shared/node_modules/formik';
import { getDefaultCountryValue } from 'shared/utils/kyc/getDefaultCountry';
import { validationSchema, paymentFormKeys, savedAddressConverter } from '../utils';
import { useLazyGetAddressForPurchaseQuery } from 'shared/state/query/purchase';
export default function usePaymentInfoForm({ isKycPassed, submitFormHandler }) {
  const [getAddress] = useLazyGetAddressForPurchaseQuery();
  const [initialValues, setInitialValues] = useState({
    [paymentFormKeys.COUNTRY]: getDefaultCountryValue,
  });

  const formikValidation = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema(isKycPassed),
    onSubmit: submitFormHandler,
    valideOnMount: true,
    enableReinitialize: true,
  });

  const { values, setFieldValue } = formikValidation;

  useEffect(() => {
    setFieldValue(paymentFormKeys.STATE, '');
  }, [values[paymentFormKeys.COUNTRY]]);

  const checkUserAddressInfo = useCallback(async () => {
    const address = await getAddress().unwrap();
    const savedAddress = address && savedAddressConverter(address);
    savedAddress && setInitialValues(savedAddress);
  }, []);

  useEffect(() => {
    if (isKycPassed) {
      checkUserAddressInfo();
    }
  }, [isKycPassed, checkUserAddressInfo]);

  return formikValidation;
}

