import React, { useContext } from 'react';
import ImageBW from '../../../../../components/ImageBoundsWrapper';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import ThemeContext from 'shared/context/ThemeContext';
import MilestoneRow from './MilestoneRow';
import {
  StreakColumnStyles,
  MilestoneRowContainer,
  MilestonePrizeItemWrapper,
  MilestonePrizeTextHolder,
  MilestonePlusText,
  MilestoneCircleText,
  MilestoneBackground,
} from '../../styledComponents';

export default function StreakMilestone(props) {
  const themeContext = useContext(ThemeContext);
  const { wheelHero, loginStreakFinal, dailyWheelAssets, enableLoyalty } = props;
  const milestoneDayLength = loginStreakFinal?.day?.toString()?.length;
  const showLoyaltyPart = enableLoyalty && loginStreakFinal?.loyalty && loginStreakFinal?.loyalty !== '0';
  return (
    <MilestoneRow hero={wheelHero ? 1 : 0}>
      <MilestoneBackground
        src={wheelHero ? dailyWheelAssets.DAILY_SPIN_COLUMN_RIBBON_HERO : dailyWheelAssets.DAILY_SPIN_COLUMN_RIBBON}
      />
      {!wheelHero && (
        <MilestoneRowContainer theme={themeContext.DailySpin.MilestoneRowContainer}>
          <MilestonePrizeItemWrapper>
            <ImageBW
              source={icons.coin}
              ibw={require(asset`loyalty-icon.ibw`)}
              style={
                showLoyaltyPart
                  ? StreakColumnStyles.mileStoneRowCoinIcon
                  : StreakColumnStyles.mileStoneRowCoinIconInCenter
              }
            />
            <MilestonePrizeTextHolder
              center={!showLoyaltyPart}
              theme={themeContext.DailySpin.MilestoneCoinsValue}
              data-label={loginStreakFinal?.coins}
            >
              {loginStreakFinal?.coins}
            </MilestonePrizeTextHolder>
          </MilestonePrizeItemWrapper>
          {showLoyaltyPart && (
            <MilestonePrizeItemWrapper>
              <MilestonePlusText data-label="+">{getText(TEXT_KEY.PLUS_SYMBOL)}</MilestonePlusText>
              <ImageBW
                source={icons.loyalty}
                ibw={require(asset`loyalty-icon.ibw`)}
                resizeMode="contain"
                style={themeContext.DailySpin.MileStoneRowLoyaltyIcon}
              />
              <MilestonePrizeTextHolder
                theme={themeContext.DailySpin.MilestoneLoyaltyValue}
                data-label={loginStreakFinal?.loyalty}
              >
                {loginStreakFinal?.loyalty}
              </MilestonePrizeTextHolder>
            </MilestonePrizeItemWrapper>
          )}
          <ImageBW
            className="FinalDayAccent"
            source={dailyWheelAssets.DAILY_SPIN_MILESTONE_CIRCLE}
            ibw={require(asset`images/daily-spin/daily-bonus-prizes-day10.ibw`)}
            style={{
              ...StreakColumnStyles.milestoneCircleImage,
              ...(!enableLoyalty && StreakColumnStyles.milestoneCircleImageCenterLayout),
            }}
            innerStyle={StreakColumnStyles.milestoneCircleImageInner}
          >
            <MilestoneCircleText
              theme={themeContext.DailySpin.MilestoneCircleValue}
              length={milestoneDayLength}
              data-label={`Milestone Day ${loginStreakFinal?.day}`}
            >
              {loginStreakFinal?.day}
            </MilestoneCircleText>
          </ImageBW>
        </MilestoneRowContainer>
      )}
    </MilestoneRow>
  );
}

const icons = {
  coin: assetSource(ASSET_KEY.COIN_ICON),
  loyalty: assetSource(ASSET_KEY.LOYALTY_ICON),
};

