import React, { useContext } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { LOGIN_ERROR_TYPE } from 'shared/utils/authFlow/constants';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import TermsModalWrapper from './TermsModalWrapper';
import { GeneralSignImModalTopSpace, SingInModalContainer, ModalButtonWrapper, ModalFooter } from '../styledComponents';

import Styles from '../styles';
import Button from '../../Button/Button';
import ThemeContext from 'shared/context/ThemeContext';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import Terms from '../../TermsAndConditions/TextLinksTermsLayout';

export default function TextLinksTermsLayoutModal(props) {
  const {
    isVisible,
    closeModal,
    closeRetryModal,
    terms,
    setTerms,
    onSubmit,
    onFacebookActivate,
    onGoogleActivate,
    error,
    socialError,
    socialErrorType,
    statusMessage,
    clientText,
    termsAndConditionsUrl,
    privacyPolicyUrl,
  } = props;
  const { TermsModal } = useContext(ThemeContext);
  return (
    <>
      <Terms
        privacyPolicyUrl={privacyPolicyUrl}
        termsAndConditionsUrl={termsAndConditionsUrl}
        clientText={clientText}
        isVisible={isVisible}
        closeModal={closeModal}
        title={getText(TEXT_KEY.TERMS_AND_CONDITIONS_TITLE)}
        modalStyle={Styles.NoAssetLayoutModalStyle}
        onSubmit={onSubmit}
        setTerms={setTerms}
        terms={terms}
        removeLeftPart
      />

      <TermsModalWrapper
        isVisible={!!error && !socialError}
        closeModal={closeModal}
        title={getText(TEXT_KEY.GENERIC_LOGIN_ERROR_TITLE)}
        zIndex={101}
        modalStyle={Styles.modalStyle}
        modalContainerStyle={Styles.modalContainerStyle}
        titleStyle={Styles.titleStyle}
      >
        <GeneralSignImModalTopSpace />
        <SingInModalContainer>
          <p className="verified-text">{getText(TEXT_KEY.GENERIC_LOGIN_ERROR_MESSAGE)}</p>
          <ModalButtonWrapper>
            <Button
              imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
              imageStyle={{ width: '195px', height: '68px' }}
              containerStyle={{ cursor: 'pointer' }}
              label={getText(TEXT_KEY.CONTACT_BUTTON)}
              onClick={() => {
                closeModal();
                window.open(ENVIRONMENT_VARIABLES.ZENDESK_URL, '_blank');
              }}
              textStyle={TermsModal.ContactUsButtonTextStyle}
              textStroke={BUTTON_STROKES.PRIMARY}
            />
          </ModalButtonWrapper>
          <ModalFooter />
        </SingInModalContainer>
      </TermsModalWrapper>
      <TermsModalWrapper
        isVisible={!!socialError && !error}
        closeModal={closeRetryModal}
        title={
          socialErrorType === LOGIN_ERROR_TYPE.FACEBOOK ? getText(TEXT_KEY.SHARE_EMAIL) : getText(TEXT_KEY.TRY_AGAIN)
        }
        zIndex={101}
        modalStyle={Styles.socialModalStyle}
        modalContainerStyle={Styles.SocialModalContainerStyle}
        titleStyle={Styles.titleStyle}
        modalMainBodyStyle={Styles.modalMainBodyStyle}
        disableClose
      >
        <SingInModalContainer>
          <p className="error-text">{typeof statusMessage === 'string' && statusMessage}</p>
          <ModalButtonWrapper>
            <Button
              imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
              imageStyle={{ width: '195px', height: '68px' }}
              containerStyle={{ cursor: 'pointer' }}
              label={getText(TEXT_KEY.RETRY_BUTTON)}
              onClick={() => {
                closeRetryModal();
                onGoogleActivate && socialErrorType === LOGIN_ERROR_TYPE.GOOGLE && onGoogleActivate();
                onFacebookActivate && socialErrorType === LOGIN_ERROR_TYPE.FACEBOOK && onFacebookActivate();
              }}
              textStyle={TermsModal.SocialErrorText}
              textStroke={BUTTON_STROKES.PRIMARY}
            />
          </ModalButtonWrapper>
          <ModalFooter />
        </SingInModalContainer>
      </TermsModalWrapper>
    </>
  );
}

