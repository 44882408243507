import generateRender from 'shared/utils/generateRender';
import DefaultForm from './DefaultForm';
import OneButtonLayoutForm from './OneButtonLayoutForm';

const ForgetPasswordForm = generateRender({
  default: DefaultForm,
  ladyluck: OneButtonLayoutForm,
});

export default ForgetPasswordForm;

