import React, { useContext } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import TermsModalWrapper from './../TermsModal/TermsModalComponents/TermsModalWrapper';
import {
  TermsContainer,
  TermsTextContainer,
  TermsUpdateScreenTextWrapper,
  TermsFooterWrapper,
  TermsItemsContainer,
  PrivacyLinkText,
  TermsLinkText,
  TermsLinkArrow,
  FooterAgreeTermsText,
  ErrorText,
} from './styledComponents';
import ImageBW from '../ImageBoundsWrapper';
import Styles from './styles';
import Button from '../Button/Button';
import ThemeContext from 'shared/context/ThemeContext';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default function TextLinksTermsLayout(props) {
  const {
    isVisible,
    closeModal,
    terms,
    setTerms,
    onSubmit,
    clientText,
    termsAndConditionsUrl,
    privacyPolicyUrl,
    removeLeftPart = false,
    hideCloseBtn = false,
    disableClose = false,
    mainText = '',
    error = '',
  } = props;
  const { TermsModal } = useContext(ThemeContext);
  return (
    <TermsModalWrapper
      isVisible={isVisible}
      closeModal={closeModal}
      title={getText(TEXT_KEY.TERMS_AND_CONDITIONS_TITLE)}
      zIndex={101}
      modalStyle={Styles.NoAssetLayoutModalStyle}
      modalMainBodyStyle={Styles.NoAssetLayoutModalBodyStyle}
      modalContainerStyle={Styles.NoAssetLayoutModalContainerStyle}
      removeLeftPart={removeLeftPart}
      hideCloseBtn={hideCloseBtn}
      disableClose={disableClose}
    >
      <TermsContainer className="terms-container">
        <TermsTextContainer>
          {error && <ErrorText>{error}</ErrorText>}
          {mainText ? (
            <TermsUpdateScreenTextWrapper>{mainText}</TermsUpdateScreenTextWrapper>
          ) : (
            <TermsUpdateScreenTextWrapper>
              {getText(TEXT_KEY.CONFIRM_TERMS_AGREE)}
              {clientText} {getText(TEXT_KEY.TERMS_AND_CONDITIONS)}.{getText(TEXT_KEY.MARKETING_CONSENT)}
              {clientText}
              {getText(TEXT_KEY.PRIVACY_POLICY_AGREE)}
              {clientText}
            </TermsUpdateScreenTextWrapper>
          )}
          <TermsItemsContainer>
            <TermsLinkText target="_blank" theme={TermsModal.TermsLinkText} href={termsAndConditionsUrl}>
              {getText(TEXT_KEY.TERMS_AND_CONDITIONS)}
              <TermsLinkArrow src={assetSource(ASSET_KEY.BACK_ARROW)} />
            </TermsLinkText>
            <PrivacyLinkText target="_blank" theme={TermsModal.TermsLinkText} href={privacyPolicyUrl}>
              {getText(TEXT_KEY.PRIVACY_POLICY)}
              <TermsLinkArrow src={assetSource(ASSET_KEY.BACK_ARROW)} />
            </PrivacyLinkText>
          </TermsItemsContainer>
        </TermsTextContainer>
      </TermsContainer>
      <TermsFooterWrapper className="terms-footer">
        <div className="accept-terms-container">
          <ImageBW
            source={terms ? assetSource(ASSET_KEY.CHECKBOX) : assetSource(ASSET_KEY.CHECKBOX_DESELECTED)}
            ibw={require(asset`checkbox.ibw`)}
            style={{
              objectFit: 'contain',
            }}
            className="checkbox-image"
            onClick={setTerms}
          />
          <FooterAgreeTermsText qa-id="checkboxTermsAndConditions" onClick={setTerms}>
            {getText(TEXT_KEY.AGREE_TERMS)}
          </FooterAgreeTermsText>
        </div>
        <div qa-id="continueBtn" className="continue-button-container">
          <Button
            imageSource={
              terms ? assetSource(ASSET_KEY.ROUNDEDRECT_PRIMARY_WIDE) : assetSource(ASSET_KEY.ROUNDEDRECT_DISABLED_WIDE)
            }
            disabled={!terms}
            imageStyle={Styles.fottetButtonStyle}
            containerStyle={Styles.fottetButtonStyle}
            onClick={terms ? onSubmit : () => {}}
            label={getText(TEXT_KEY.CONTINUE_BUTTON)}
            textStroke={BUTTON_STROKES.PRIMARY}
          />
        </div>
      </TermsFooterWrapper>
    </TermsModalWrapper>
  );
}

