import { useEffect, useRef } from 'react';

function useCheckOutsideClick(handler, listenCapturing = true) {
  const boxRef = useRef();

  const checkEvent = e => {
    if (boxRef.current && !boxRef.current.contains(e.target)) handler?.();
  };

  useEffect(() => {
    document.addEventListener('click', checkEvent, {
      capture: listenCapturing,
    });
    return () => {
      document.removeEventListener('click', checkEvent, {
        capture: listenCapturing,
      });
    };
  }, [listenCapturing]);

  return {
    boxRef,
  };
}

export default useCheckOutsideClick;

