import React, { Component } from 'react';
import { Form, Tooltip, Button as GenericButton, Overlay } from 'react-bootstrap';
import styled from 'styled-components';
import Validators from 'shared/components/Form/InputValidator';
import AutoFormInput from '../../components/AutoFormInput';
import FormType from 'shared/components/Form/FormType';
import FormId from 'shared/components/Form/FormId';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import Button from '../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import ThemeContext from 'shared/context/ThemeContext';
import LinkText from '../../components/LinkText/LinkText';
import { CREDIT_CARD } from './utils';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

export default class BillingProfile extends Component {
  constructor(...args) {
    super(...args);
    this.attachRef = target => this.setState({ target });

    this.state = {
      profileSelected: this.props.profiles[0].profile_id,
      promoCode: '',
      showDeleteConfirmation: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profiles.length !== nextProps.profiles.length && nextProps.profiles.length > 0) {
      this.setState({ profileSelected: nextProps.profiles[0].profile_id });
    }
  }

  render() {
    const {
      profiles,
      buyWithBillingProfile,
      deleteBillingProfile,
      purchaseType,
      promoReward,
      getPurchaseStrings,
      renderTotal,
      packageData,
      hasOffer,
      termsAndConditionsURL,
    } = this.props;
    const themeContext = this.context;

    const profileOptions = profiles.map(profile => ({
      value: profile.profile_id,
      label: profile?.lastDigits ? `${profile.profileNickname} - ${profile.lastDigits}` : profile.profileNickname,
    }));

    if (packageData === null) return null;

    let { purchaseNameString, purchasePriceString } = getPurchaseStrings(purchaseType, packageData, hasOffer);

    const { creditCardType } = profiles.find(({ profile_id }) => profile_id === this.state.profileSelected) || {};

    return (
      <FormWrapper>
        <Form.Group controlId="billingProfileSelect">
          <CreditCardWrapper>
            {creditCardType && <CreditCardImage src={CREDIT_CARD[creditCardType]} alt="" />}
          </CreditCardWrapper>
          <Form.Label className="billingProfileLabel">
            {getText(TEXT_KEY.PAYMENT_INFO_SELECT_METHOD)}
            <span className="profileControls">
              <span style={themeContext.BillingProfile.AddDeleteProfileText} onClick={() => buyWithBillingProfile(-1)}>
                {getText(TEXT_KEY.ADD)}
              </span>
              {profiles.length > 0 && parseInt(profiles[0].profile_id) >= 0 && (
                <>
                  <span
                    ref={this.attachRef}
                    style={themeContext.BillingProfile.AddDeleteProfileText}
                    onClick={() =>
                      this.setState({
                        showDeleteConfirmation: !this.state.showDeleteConfirmation,
                      })
                    }
                  >
                    {getText(TEXT_KEY.DELETE)}
                  </span>
                  <Overlay target={this.state.target} show={this.state.showDeleteConfirmation} placement="bottom">
                    {props => (
                      <Tooltip {...props}>
                        <div style={{ margin: '1rem' }}>
                          <h4>{getText(TEXT_KEY.CONFIRM_DELETE_CARD)}</h4>
                          <GenericButton
                            block
                            onClick={() => {
                              deleteBillingProfile(parseInt(this.state.profileSelected));

                              this.setState({
                                showDeleteConfirmation: !this.state.showDeleteConfirmation,
                              });
                            }}
                          >
                            {getText(TEXT_KEY.YES)}
                          </GenericButton>
                          <GenericButton
                            block
                            onClick={() =>
                              this.setState({
                                showDeleteConfirmation: false,
                              })
                            }
                          >
                            {getText(TEXT_KEY.CANCEL)}
                          </GenericButton>
                        </div>
                      </Tooltip>
                    )}
                  </Overlay>
                </>
              )}
            </span>
          </Form.Label>
          <div
            className="billingProfileSelect"
            onChange={evt =>
              this.setState({
                profileSelected: evt.target.value,
              })
            }
          >
            <AutoFormInput
              controlId={FormId.BILLING_PROFILE}
              id={FormId.BILLING_PROFILE}
              validator={Validators.NO_VALIDATION}
              type={FormType.DROPDOWN}
              keyboardHint={''}
              label={''}
              options={profileOptions}
              validated={true}
              emitInput={() => {}}
              customStyle={themeContext.PurchaseFlow.billingProfileSelect}
            />
            <img
              style={themeContext.PurchaseFlow.BillingProfileDownArrowIcon}
              src={assetSource(ASSET_KEY.CARET_DOWN)}
              alt=""
            />
          </div>
        </Form.Group>
        {renderTotal(purchaseNameString, purchasePriceString, promoReward)}
        <BuyButtonWrapper>
          <Button
            textStroke={BUTTON_STROKES.PRIMARY}
            disabled={profiles?.length === 0}
            imageSource={
              profiles.length > 0
                ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)
                : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
            }
            onClick={profiles.length > 0 ? () => buyWithBillingProfile(parseInt(this.state.profileSelected)) : () => {}}
            label={getText(TEXT_KEY.BUY_NOW)}
            textStyle={{ fontSize: '1.2vw' }}
          />
        </BuyButtonWrapper>
        <TermsWrapper>
          <LinkText target="_blank" href={termsAndConditionsURL}>
            {getText(TEXT_KEY.TERMS_AND_CONDITIONS_IAP)}
          </LinkText>
        </TermsWrapper>
      </FormWrapper>
    );
  }
}

BillingProfile.contextType = ThemeContext;

const FormWrapper = styled.div`
  margin-top: 2rem;
`;

const CreditCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  height: 2.5rem;
`;

const CreditCardImage = styled.img`
  height: 100%;
`;

const BuyButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
`;

const TermsWrapper = styled.div`
  justify-content: center;
  margin-top: 5;
  display: flex;
`;

