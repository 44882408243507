import React from 'react';
import ComingSoon from '../../Start/components/ComingSoon';
import SmartBanner from '../../../components/SmartBanner';

function ComingSoonLayout() {
  return (
    <ComingSoon>
      <SmartBanner style={{ position: 'unset' }} disableBanner />
    </ComingSoon>
  );
}

export default ComingSoonLayout;

