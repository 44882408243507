import React from 'react';
import Button from '../../../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import useThemeContext from 'shared/context/hooks/useThemeContext';
import { RESET_PASS_ACTIONS } from '../../constants';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import {
  MainContainer,
  FormInputLabel,
  TopText,
  MessageTextWrapper,
  MessageText,
  FormWrapper,
  InputHeader,
  FormInput,
  ButtonWrapper,
  styles,
} from '../styledComponents';

function DefaultFormContainer({
  onSumbitButtonClick,
  closeModal,
  response,
  error,
  message,
  dispatch,
  recoverId,
  recoverToken,
  password,
  confirmPassword,
  loading,
  getText,
  textKeys: TEXT_KEY,
}) {
  const { ResetPassword } = useThemeContext();

  return (
    <MainContainer>
      <TopText>
        {!response
          ? getText(TEXT_KEY.TYPE_NEW_PASSWORD_BELOW)
          : !error
            ? getText(TEXT_KEY.PASSWORD_HAS_BEEN_RESET)
            : ''}
      </TopText>
      <FormWrapper className="recoverPasswordForm">
        <FormInputLabel>
          <InputHeader theme={ResetPassword.InputLabel}>{getText(TEXT_KEY.NEW_PASSWORD)}</InputHeader>
          <FormInput
            theme={ResetPassword.FormInputsFields}
            type="password"
            maxLength="50"
            value={password}
            onChange={e => dispatch({ type: RESET_PASS_ACTIONS.UPDATE_PASSWORD, payload: e.target.value })}
          />
        </FormInputLabel>
        <FormInputLabel>
          <InputHeader theme={ResetPassword.InputLabel}>{getText(TEXT_KEY.CONFIRM_PASSWORD)}</InputHeader>
          <FormInput
            theme={ResetPassword.FormInputsFields}
            type="password"
            maxLength="50"
            value={confirmPassword}
            onChange={e => dispatch({ type: RESET_PASS_ACTIONS.CONFIRM_PASSWORD, payload: e.target.value })}
          />
        </FormInputLabel>
      </FormWrapper>
      {message && (
        <MessageTextWrapper>
          <MessageText error={error}>{message}</MessageText>
        </MessageTextWrapper>
      )}
      <ButtonWrapper>
        {response && !error ? (
          <Button
            imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
            label={getText(TEXT_KEY.THANKS)}
            onClick={closeModal}
            textStroke={BUTTON_STROKES.PRIMARY}
          />
        ) : (
          <>
            <Button
              isDebounce={true}
              imageSource={
                !loading ? assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY) : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
              }
              label={getText(TEXT_KEY.SUBMIT)}
              onClick={() => onSumbitButtonClick(recoverId, recoverToken)}
              imageStyle={styles.buttonImageStyle}
              textStroke={BUTTON_STROKES.PRIMARY}
              disabled={loading}
            />
            <Button
              imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_SECONDARY)}
              label={getText(TEXT_KEY.CANCEL)}
              onClick={closeModal}
              textStroke={BUTTON_STROKES.SECONDARY}
              imageStyle={styles.buttonImageStyle}
            />
          </>
        )}
      </ButtonWrapper>
    </MainContainer>
  );
}

export default DefaultFormContainer;

