import { createSlice } from '@reduxjs/toolkit';
var sliceName = 'PROGRESSIVE_JACKPOTS';
var initialState = {
  data: []
};
var progressiveJackpotsSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    progressiveJackpotsRequestDataSuccess: function progressiveJackpotsRequestDataSuccess(state, action) {
      var _action$payload$resul;
      state.data = (_action$payload$resul = action.payload.result) === null || _action$payload$resul === void 0 ? void 0 : _action$payload$resul.progressiveJackpots;
    }
  }
});
var actions = progressiveJackpotsSlice.actions,
  reducer = progressiveJackpotsSlice.reducer;
var progressiveJackpotsRequestDataSuccess = actions.progressiveJackpotsRequestDataSuccess;
export { progressiveJackpotsRequestDataSuccess };
export default reducer;