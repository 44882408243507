import generateRender from 'shared/utils/generateRender';
import DefaultPurchaseHeader from '../../../../../../components/JSModal/components/DefaultHeader';
import HighlightedPurchaseHeader from '../../../../../../components/JSModal/components/HighlightedHeader';

const RespopnseHeader = generateRender({
  default: DefaultPurchaseHeader,
  jamul: HighlightedPurchaseHeader,
  ladyluck: HighlightedPurchaseHeader,
});

export default RespopnseHeader;

