import { Endpoints } from '../../../api/Constants';
import { EVENT_TYPES } from '../../../lib/analytics/constants';
import Logger from '../../../lib/analytics/logger';
import ERROR_CODE from '../../../utils/errorCodes';
import HTTP from '../../../utils/httpMethods';
import { validateJson } from '../../../utils/jsonschema/validator';
import { getTimeout } from '../../api/services/utils/config';
import { handleQueryLoggerEvents } from '../handleLoggerErrorEvents';
import { getDailyWheelDataAdapter } from './adapters';
export var getDailyWheelBonusQuery = {
  query: function query() {
    return {
      url: Endpoints.CLAIM_DAILY_BONUS,
      body: {
        timeout: getTimeout(Endpoints.CLAIM_DAILY_BONUS, HTTP.GET)
      }
    };
  }
};
export var getDailyWheelDataQuery = {
  query: function query() {
    return {
      url: Endpoints.GET_DAILY_WHEEL_INFO
    };
  },
  transformResponse: function transformResponse(response) {
    try {
      if (!(response !== null && response !== void 0 && response[0]) || Object.keys(response === null || response === void 0 ? void 0 : response[0]).length === 0 && (response === null || response === void 0 ? void 0 : response[0].constructor) === Object) return false;
      if (!validateJson(Endpoints.GET_DAILY_WHEEL_INFO, response)) return false;
      return getDailyWheelDataAdapter(response[0]);
    } catch (error) {
      Logger.sendEvent(EVENT_TYPES.FRONTEND_ERROR, {
        error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
        code: ERROR_CODE.GET_DAILY_BONUS_FAIL
      });
      handleQueryLoggerEvents(error);
    }
  }
};