import React, { useContext } from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { useSelector } from 'shared/node_modules/react-redux';
import ThemeContext from 'shared/context/ThemeContext';
import { BottomLeftPartIcon, BottomBarPlusText, BottomBarStyles } from '../../styledComponents';
export default function BottomBarLeftSide(props) {
  const showLinkLoyaltyCardSetting = useSelector(state => state.properties.data.showLinkLoyaltyCardSetting);
  const themeContext = useContext(ThemeContext);

  const {
    dailyWheelAssets,
    step,
    loyaltyCardComingSoon,
    wheelBonus,
    dailyBonus,
    linkedAccount,
    currentTierBonus,
    cardIconPath,
  } = props;
  return (
    <React.Fragment>
      <div
        style={
          showLinkLoyaltyCardSetting
            ? themeContext.DailySpin.BottomBarLeftContainerWithCard
            : themeContext.DailySpin.BottomBarLeftContainerNoCard
        }
        className={`bonusContainer ${step === 2 ? 'WheelBonusAnimation' : 'BonusContainerSmall'}`}
      >
        <BottomLeftPartIcon src={dailyWheelAssets.DAILY_SPIN_WHEEL_ICON} alt="Small Wheel" />
        <span className="bonusAmount" data-label={wheelBonus}>
          {wheelBonus}
        </span>
        <span
          style={
            showLinkLoyaltyCardSetting
              ? themeContext.DailySpin.BottomBarLeftContainerWithCardText
              : themeContext.DailySpin.BottomBarLeftContainerNoCardText
          }
          className="bonusInfo"
          data-label="WHEEL BONUS"
        >
          {getText(TEXT_KEY.WHEEL_BONUS)}
        </span>
      </div>
      <BottomBarPlusText>{getText(TEXT_KEY.PLUS_SYMBOL)}</BottomBarPlusText>
      <div
        style={
          showLinkLoyaltyCardSetting
            ? themeContext.DailySpin.BottomBarLeftContainerWithCard
            : themeContext.DailySpin.BottomBarLeftContainerNoCard
        }
        className={`bonusContainer ${step === 2 ? 'DailyBonusAnimation' : 'BonusContainerSmall'}`}
      >
        <BottomLeftPartIcon src={dailyWheelAssets.DAILY_SPIN_CALENDAR_ICON} alt="Calendar" />
        <span className="bonusAmount" data-label={dailyBonus}>
          {dailyBonus}
        </span>
        <span
          style={
            showLinkLoyaltyCardSetting
              ? themeContext.DailySpin.BottomBarLeftContainerWithCardText
              : themeContext.DailySpin.BottomBarLeftContainerNoCardText
          }
          className="bonusInfo"
          data-label="DAILY BONUS"
        >
          {getText(TEXT_KEY.DAILY_BONUS)}
        </span>
      </div>
      {showLinkLoyaltyCardSetting && (
        <>
          <BottomBarPlusText>{getText(TEXT_KEY.PLUS_SYMBOL)}</BottomBarPlusText>
          <div className={`bonusContainer ${step === 2 ? 'TierBonusAnimation' : 'BonusContainerSmall'}`}>
            <BottomLeftPartIcon src={cardIconPath ? cardIconPath : dailyWheelAssets.DAILY_SPIN_CARDS_ICON} alt="Card" />
            <span
              className="bonusAmount"
              style={BottomBarStyles.comingSoonCardtext}
              data-label={
                loyaltyCardComingSoon
                  ? `${getText(TEXT_KEY.COMING_SOON_TITLE)}`
                  : linkedAccount
                    ? `${currentTierBonus}%`
                    : '-'
              }
            >
              {loyaltyCardComingSoon
                ? `${getText(TEXT_KEY.COMING_SOON_TITLE)}`
                : linkedAccount
                  ? `${currentTierBonus}%`
                  : '-'}
            </span>
            {!loyaltyCardComingSoon && (
              <span className={linkedAccount ? 'bonusInfo' : 'tierNotLinked'} data-label="TIER BONUS">
                {linkedAccount ? getText(TEXT_KEY.TIER_BONUS) : getText(TEXT_KEY.TIER_BONUS_NOT_LINKED)}
              </span>
            )}
          </div>
        </>
      )}
    </React.Fragment>
  );
}

