import React from 'react';
import Button from '../../../../components/Button/Button';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import useThemeContext from 'shared/context/hooks/useThemeContext';
import { RESET_PASS_ACTIONS } from '../../constants';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import BoxyLayoutTextInputsWrapper from '../../../../components/TextInput/BoxyLayoutTextInputsWrapper';
import { TextInput } from '../../../../components/TextInput';

import {
  MainContainer,
  BoxyLayoutFormInputWrapper,
  BoxyLayoutTopText,
  MessageTextWrapper,
  MessageText,
  BoxyFormWrapper,
  SingleButtonWrapper,
  WideButtonsWrapper,
  PeekerBoxyStyle,
  InputPasswordPeeker,
  iconTheme,
  styles,
} from '../styledComponents';

function BoxyLayoutFormContainer({
  onSumbitButtonClick,
  closeModal,
  response,
  error,
  message,
  dispatch,
  recoverId,
  recoverToken,
  password,
  confirmPassword,
  loading,
  getText,
  textKeys: TEXT_KEY,
  showPasswords,
}) {
  const { SignUp } = useThemeContext();

  return (
    <MainContainer>
      <BoxyLayoutTopText>
        {!response
          ? getText(TEXT_KEY.TYPE_NEW_PASSWORD_BELOW)
          : !error
            ? getText(TEXT_KEY.PASSWORD_HAS_BEEN_RESET)
            : ''}
      </BoxyLayoutTopText>
      <BoxyFormWrapper className="recoverPasswordForm">
        <BoxyLayoutFormInputWrapper>
          <BoxyLayoutTextInputsWrapper
            checkErrorLength
            icon={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_REGULAR)}
            iconError={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_ERROR)}
            error={error ? message : false}
            disableErrorWrapper={isMobile}
            iconTheme={iconTheme}
          >
            <TextInput
              theme={SignUp.FormInputTheme}
              icon
              boxErrorLayout
              error={error && !isMobile ? message : false}
              type={!showPasswords ? 'password' : 'text'}
              maxLength="50"
              value={password}
              onChange={e => dispatch({ type: RESET_PASS_ACTIONS.UPDATE_PASSWORD, payload: e.target.value })}
              placeholder={getText(TEXT_KEY.NEW_PASSWORD)}
            />
            <PeekerBoxyStyle onClick={() => dispatch({ type: RESET_PASS_ACTIONS.TOGGLE_PASSWORD })}>
              {!showPasswords && <InputPasswordPeeker src={assetSource(ASSET_KEY.ICON_SHOW)} />}
              {showPasswords && <InputPasswordPeeker src={assetSource(ASSET_KEY.ICON_HIDE)} />}
            </PeekerBoxyStyle>
          </BoxyLayoutTextInputsWrapper>
        </BoxyLayoutFormInputWrapper>
        <BoxyLayoutFormInputWrapper>
          <BoxyLayoutTextInputsWrapper
            checkErrorLength
            icon={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_REGULAR)}
            iconError={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_ERROR)}
            error={error ? message : false}
            iconTheme={iconTheme}
            disableErrorWrapper={isMobile}
          >
            <TextInput
              theme={SignUp.FormInputTheme}
              icon
              boxErrorLayout
              error={error && !isMobile ? message : false}
              type={!showPasswords ? 'password' : 'text'}
              maxLength="50"
              value={confirmPassword}
              onChange={e => dispatch({ type: RESET_PASS_ACTIONS.CONFIRM_PASSWORD, payload: e.target.value })}
              placeholder={getText(TEXT_KEY.CONFIRM_PASSWORD)}
            />
            <PeekerBoxyStyle onClick={() => dispatch({ type: RESET_PASS_ACTIONS.TOGGLE_PASSWORD })}>
              {!showPasswords && <InputPasswordPeeker src={assetSource(ASSET_KEY.ICON_SHOW)} />}
              {showPasswords && <InputPasswordPeeker src={assetSource(ASSET_KEY.ICON_HIDE)} />}
            </PeekerBoxyStyle>
          </BoxyLayoutTextInputsWrapper>
        </BoxyLayoutFormInputWrapper>
      </BoxyFormWrapper>
      {message && (!error || isMobile) && (
        <MessageTextWrapper>
          <MessageText error={error}>{message}</MessageText>
        </MessageTextWrapper>
      )}
      {response && !error ? (
        <SingleButtonWrapper>
          <Button
            imageSource={assetSource(ASSET_KEY.ROUNDEDRECT_PRIMARY_WIDE)}
            label={getText(TEXT_KEY.THANKS)}
            onClick={closeModal}
            textStroke={BUTTON_STROKES.PRIMARY}
            imageStyle={styles.wideButtonStyle}
            containerStyle={styles.wideButtonStyle}
          />
        </SingleButtonWrapper>
      ) : (
        <WideButtonsWrapper>
          <Button
            isDebounce={true}
            imageSource={
              !loading
                ? assetSource(ASSET_KEY.ROUNDEDRECT_PRIMARY_WIDE)
                : assetSource(ASSET_KEY.ROUNDEDRECT_DISABLED_WIDE)
            }
            label={getText(TEXT_KEY.SUBMIT)}
            onClick={() => onSumbitButtonClick(recoverId, recoverToken)}
            imageStyle={styles.wideButtonStyle}
            containerStyle={styles.wideButtonStyle}
            textStroke={BUTTON_STROKES.PRIMARY}
            disabled={loading}
          />
          <Button
            imageSource={assetSource(ASSET_KEY.ROUNDEDRECT_DISABLED_WIDE)}
            label={getText(TEXT_KEY.CANCEL)}
            onClick={closeModal}
            textStroke={BUTTON_STROKES.DISABLED}
            imageStyle={styles.wideButtonStyle}
            containerStyle={styles.wideButtonStyle}
          />
        </WideButtonsWrapper>
      )}
    </MainContainer>
  );
}

export default BoxyLayoutFormContainer;

const isMobile = (window.innerHeight < 500 && window.innerWidth < 1000) || window.innerWidth < 600;

