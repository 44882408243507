import generateRender from 'shared/utils/generateRender';
import DefaultModalHeader from '../../../../components/JSModal/components/DefaultHeader';
import HighlightedModalHeader from '../../../../components/JSModal/components/HighlightedHeader';

const VipAccessHeader = generateRender({
  default: DefaultModalHeader,
  jamul: HighlightedModalHeader,
  ladyluck: HighlightedModalHeader,
});

export default VipAccessHeader;

