import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { trimInputString } from 'shared/utils/string';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import {
  FormInputContainer,
  PeekerBoxyStyle,
  NewUserForm,
  LeftInputContainer,
  RightInputContainer,
  InputPasswordPeeker,
} from '../../common/styledComponents';
import { TextInput } from '../../../../components/TextInput';
import BoxyLayoutTextInputsWrapper from '../../../../components/TextInput/BoxyLayoutTextInputsWrapper';

function FormInputs({
  showPassword,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  showPasswordToggleHandle,
  signUpTheme,
  values,
  requireName,
}) {
  const { firstName, lastName, email, password, confirmPassword } = values;
  return (
    <>
      {requireName && (
        <NewUserForm>
          <LeftInputContainer theme={signUpTheme.MultipleInputInRowWrapper}>
            <FormInputContainer>
              <BoxyLayoutTextInputsWrapper error={errors?.firstName}>
                <TextInput
                  theme={signUpTheme.FormInputTheme}
                  error={!!errors.firstName}
                  boxErrorLayout
                  type="text"
                  value={firstName}
                  maxLength="50"
                  onChange={handleChange('firstName')}
                  onBlur={handleBlur('firstName')}
                  placeholder={getText(TEXT_KEY.FIRST_NAME)}
                />
              </BoxyLayoutTextInputsWrapper>
            </FormInputContainer>
          </LeftInputContainer>
          <RightInputContainer theme={signUpTheme.MultipleInputInRowWrapper}>
            <FormInputContainer>
              <BoxyLayoutTextInputsWrapper error={errors?.lastName}>
                <TextInput
                  theme={signUpTheme.FormInputTheme}
                  boxErrorLayout
                  error={!!errors.lastName}
                  type="text"
                  value={lastName}
                  maxLength="50"
                  onChange={handleChange('lastName')}
                  onBlur={handleBlur('lastName')}
                  placeholder={getText(TEXT_KEY.LAST_NAME)}
                />
              </BoxyLayoutTextInputsWrapper>
            </FormInputContainer>
          </RightInputContainer>
        </NewUserForm>
      )}
      <FormInputContainer>
        <BoxyLayoutTextInputsWrapper
          icon={assetSource(ASSET_KEY.INPUT_EMAIL_ICON_REGULAR)}
          iconError={assetSource(ASSET_KEY.INPUT_EMAIL_ICON_ERROR)}
          error={errors?.email}
        >
          <TextInput
            theme={signUpTheme.FormInputTheme}
            icon
            boxErrorLayout
            error={!!errors?.email}
            type="text"
            maxLength="100"
            value={email}
            onChange={e => setFieldValue('email', trimInputString(e.target.value))}
            onBlur={handleBlur('email')}
            placeholder={getText(TEXT_KEY.EMAIL)}
          />
        </BoxyLayoutTextInputsWrapper>
      </FormInputContainer>

      <NewUserForm>
        <LeftInputContainer theme={signUpTheme.MultipleInputInRowWrapper}>
          <FormInputContainer>
            <BoxyLayoutTextInputsWrapper
              checkErrorLength
              icon={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_REGULAR)}
              iconError={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_ERROR)}
              error={errors?.password}
            >
              <TextInput
                theme={signUpTheme.FormInputTheme}
                icon
                boxErrorLayout
                error={!!errors?.password}
                type={!showPassword ? 'password' : 'text'}
                maxLength="50"
                value={password}
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                placeholder={getText(TEXT_KEY.PASSWORD)}
              />
              <PeekerBoxyStyle onClick={showPasswordToggleHandle}>
                {!showPassword && <InputPasswordPeeker src={assetSource(ASSET_KEY.ICON_SHOW)} />}
                {showPassword && <InputPasswordPeeker src={assetSource(ASSET_KEY.ICON_HIDE)} />}
              </PeekerBoxyStyle>
            </BoxyLayoutTextInputsWrapper>
          </FormInputContainer>
        </LeftInputContainer>
        <RightInputContainer theme={signUpTheme.MultipleInputInRowWrapper}>
          <FormInputContainer>
            <BoxyLayoutTextInputsWrapper
              checkErrorLength
              icon={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_REGULAR)}
              iconError={assetSource(ASSET_KEY.INPUT_PASSWORD_ICON_ERROR)}
              error={errors?.confirmPassword}
            >
              <TextInput
                theme={signUpTheme.FormInputTheme}
                icon
                boxErrorLayout
                error={!!errors?.confirmPassword}
                type={!showPassword ? 'password' : 'text'}
                maxLength="50"
                value={confirmPassword}
                onChange={handleChange('confirmPassword')}
                onBlur={handleBlur('confirmPassword')}
                placeholder={getText(TEXT_KEY.CONFIRM_PASSWORD)}
              />
            </BoxyLayoutTextInputsWrapper>
          </FormInputContainer>
        </RightInputContainer>
      </NewUserForm>
    </>
  );
}

export default FormInputs;

