import React from 'react';
import styled from 'styled-components';

import Tooltip from '../../Tooltip';
import { TooltipTypes } from 'shared/utils/tooltipConstants';
import { getText, TEXT_KEY } from 'shared/utils/localization';

function ComingSoonTooltip({ showActiveTracker, tooltipType }) {
  return (
    <Tooltip
      style={{
        marginLeft: showActiveTracker ? '1vmin' : '-9%',
        ...styles.comingSoonTooltipContainer,
      }}
      arrowStyle={styles.tooltipArrowStyle}
      visible={tooltipType && tooltipType === TooltipTypes.TIER_COMING_SOON}
    >
      <ComingSoonText>{getText(TEXT_KEY.COMING_SOON_TITLE)}</ComingSoonText>
    </Tooltip>
  );
}

export default ComingSoonTooltip;

const ComingSoonText = styled.div`
  width: '100%';
  height: '100%';
  text-transform: uppercase;
`;

const styles = {
  tooltipArrowStyle: {
    left: window.innerWidth < 1400 ? 72 : 65,
  },
  comingSoonTooltipContainer: {
    height: '5rem',
    width: 250,
    top: '7rem',
    left: window.innerWidth < 1400 ? -40 : 0,
  },
};

