import React from 'react';
import Button from '../../../components/Button/Button';
import generateRender from 'shared/utils/generateRender';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import {
  MessageContainerWrapper,
  MessageTextWrapper,
  MessageText,
  ErrorButtonsWrapper,
  styles,
} from './styledComponents';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';

function MessageContainer({ errorMessage, closeModal, response, error, message, getText, textKeys: TEXT_KEY }) {
  return (
    <MessageContainerWrapper>
      <MessageTextWrapper>
        <MessageText error={error || errorMessage}>
          {!response || error ? errorMessage || message : message}
        </MessageText>
      </MessageTextWrapper>
      <ErrorButtonsWrapper>
        {response && !error ? (
          <ThanksButtonLayout label={getText(TEXT_KEY.THANKS)} onClick={closeModal} />
        ) : (
          <OkButtonLayout label={getText(TEXT_KEY.OK)} onClick={closeModal} />
        )}
      </ErrorButtonsWrapper>
    </MessageContainerWrapper>
  );
}

export default MessageContainer;

const DefaultOkButton = props => (
  <Button imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)} textStroke={BUTTON_STROKES.PRIMARY} {...props} />
);

const DefaultThanksButton = props => (
  <Button
    imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_SECONDARY)}
    textStroke={BUTTON_STROKES.SECONDARY}
    {...props}
  />
);

const WideButton = props => (
  <Button
    imageSource={assetSource(ASSET_KEY.ROUNDEDRECT_PRIMARY_WIDE)}
    textStroke={BUTTON_STROKES.PRIMARY}
    imageStyle={styles.wideButtonStyle}
    containerStyle={styles.wideButtonStyle}
    {...props}
  />
);

const OkButtonLayout = generateRender({
  default: DefaultOkButton,
  ladyluck: WideButton,
});

const ThanksButtonLayout = generateRender({
  default: DefaultThanksButton,
  ladyluck: WideButton,
});

