import { format, getFullUnitName, transformUnits } from './utils';
import transformObjectResponseKeys from '../../../utils/transformObjectResponseKeys';
import transformObjectArrayResponse from '../../../utils/transformObjectArrayResponse';
import Logger from '../../../lib/analytics/logger';
import { EVENT_TYPES } from '../../../lib/analytics/constants';
import { CurrencyPackageNewsListItemKeys, CurrencyPackagesResponseKeys, CurrencyPackageItemKeys } from '../../../api/Constants';
export var getBillingProfileAdapter = function getBillingProfileAdapter(response) {
  if (response.billingProfiles !== undefined) {
    response.billingProfiles = transformObjectArrayResponse(response.billingProfiles);
  }
  return response;
};
export var checkToRedirectOnSuccess = function checkToRedirectOnSuccess(data, response) {
  var redirectOnSuccess = data.redirectOnSuccess,
    billing = data.billing;
  if (redirectOnSuccess && response !== null && response !== void 0 && response.success) {
    var expiryMonth = billing.expiryMonth.toString().length < 2 ? "0".concat(billing.expiryMonth) : billing.expiryMonth;
    var newLoc = "".concat(response.formurl, "?billing-cc-number=").concat(billing.cc, "&billing-cvv=").concat(billing.cvv, "&billing-cc-exp=").concat(expiryMonth).concat(billing.expiryYear);
    window.location = newLoc;
  }
};
export var currencyPackageTransformer = function currencyPackageTransformer(response) {
  var transformed = transformObjectResponseKeys(response, CurrencyPackagesResponseKeys);
  transformed.NEWS_LIST = transformObjectArrayResponse(transformed.NEWS_LIST);
  for (var i = 0; i < transformed.NEWS_LIST.length; i++) {
    transformed.NEWS_LIST[i] = transformObjectResponseKeys(transformed.NEWS_LIST[i], CurrencyPackageNewsListItemKeys);
  }
  var coinsPackageListInfo = transformed.PACKAGE_LIST['coins'];
  coinsPackageListInfo.items = transformObjectArrayResponse(coinsPackageListInfo.items);
  if (coinsPackageListInfo.items.length == 0) {
    var eventPayload = {
      description: 'No currency packages returned from server',
      code: 'C01'
    };
    Logger.sendEvent(EVENT_TYPES.ERROR_CREATED, eventPayload);
  }
  var coinPackageItems = coinsPackageListInfo.items;
  for (var _i = 0; _i < coinPackageItems.length; _i++) {
    coinPackageItems[_i] = transformObjectResponseKeys(coinPackageItems[_i], CurrencyPackageItemKeys);
  }
  for (var _i2 = 0; _i2 < coinPackageItems.length; _i2++) {
    coinPackageItems[_i2].AMOUNT = format(coinPackageItems[_i2].AMOUNT);
    coinPackageItems[_i2].ALT_AMOUNT = format(coinPackageItems[_i2].ALT_AMOUNT);
    coinPackageItems[_i2].ORIGINAL_AMOUNT = format(coinPackageItems[_i2].ORIGINAL_AMOUNT);
    coinPackageItems[_i2].BONUS_LOYALTY_AMOUNT = format(coinPackageItems[_i2].BONUS_LOYALTY_AMOUNT);
    coinPackageItems[_i2].PROMO_BOOSTED_VALUE = format(coinPackageItems[_i2].PROMO_BOOSTED_VALUE);
    coinPackageItems[_i2].AMOUNT_INCLUDING_SALE_AND_PROMO = format(coinPackageItems[_i2].AMOUNT_INCLUDING_SALE_AND_PROMO);
    coinPackageItems[_i2].PROMO_BOOST_PERCENTAGE = format(transformed.PACKAGE_LIST.coins.promo_percentage);
    coinPackageItems[_i2].SALE_PERCENTAGE = format(transformed.PACKAGE_LIST.coins.sale_percentage);
  }
  transformed.PACKAGE_LIST.vip_room_access.items = transformObjectArrayResponse(transformed.PACKAGE_LIST.vip_room_access.items);
  var vipPackageItems = transformed.PACKAGE_LIST.vip_room_access.items;
  for (var _i3 = 0; _i3 < vipPackageItems.length; _i3++) {
    var _transformUnits = transformUnits(vipPackageItems[_i3].duration_in_seconds, 'Sec'),
      value = _transformUnits.value,
      unitShort = _transformUnits.unit;
    var unit = getFullUnitName(unitShort, value);
    vipPackageItems[_i3].duration_friendly = "".concat(value, " ").concat(unitShort);
    vipPackageItems[_i3].duration_friendly_full = "".concat(value, " ").concat(unit);
  }
  return transformed;
};