import React from 'react';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { BUTTON_STROKES } from 'shared/utils/buttonTextStrokes';
import Button from '../../../../components/Button/Button';
import generateRender from 'shared/utils/generateRender';

function FormButtonDefault(props) {
  const { isLandingPage, disabled } = props;
  return (
    <Button
      imageStyle={isLandingPage ? { width: '15em' } : null}
      label={FormButtonText()}
      imageSource={
        !disabled
          ? isLandingPage
            ? assetSource(ASSET_KEY.GREEN_BUTTON_LANDING)
            : assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)
          : isLandingPage
            ? assetSource(ASSET_KEY.GREY_BUTTON_LANDING)
            : assetSource(ASSET_KEY.ROUNDED_RECT_DISABLED)
      }
      {...props}
    />
  );
}

function FormButtonMillelacs(props) {
  const { disabled, isLandingPage } = props;
  return (
    <Button
      imageSource={
        !disabled ? assetSource(ASSET_KEY.ROUNDEDRECT_PRIMARY_WIDE) : assetSource(ASSET_KEY.ROUNDEDRECT_DISABLED_WIDE)
      }
      imageStyle={{ width: isLandingPage ? '15em' : '18em' }}
      {...props}
      label={FormButtonText()}
      textStroke={BUTTON_STROKES.PRIMARY}
    />
  );
}

const FormButtonText = () =>
  generateRender({
    default: getText(TEXT_KEY.REGISTER),
    millelacs: getText(TEXT_KEY.SIGN_UP),
    jamul: getText(TEXT_KEY.SIGN_UP),
    ladyluck: getText(TEXT_KEY.SIGN_UP),
  });

const FormButton = generateRender({
  default: FormButtonDefault,
  millelacs: FormButtonMillelacs,
  ladyluck: FormButtonMillelacs,
});

export default FormButton;

