import { PURCHASE_CACHE_TAGS } from '../tags';
import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { postFinishMobilePurchaseMutation, postBuyItemWithBillingProfileMutation, postRemoveBillingProfileMutation, postStartWebPurchaseMutation, postFinishWebPurchaseMutation, postPurchaseWithCasinoDollarsMutation, getBillingProfilesQuery, getAddressForPurchaseQuery, getRefreshActiveConsumablesQuery, getCurrencyPackagesQuery } from './queries';
export var purchaseApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      postFinishMobilePurchase: builder.mutation(postFinishMobilePurchaseMutation),
      postBuyItemWithBillingProfile: builder.mutation(postBuyItemWithBillingProfileMutation),
      postRemoveBillingProfile: builder.mutation(postRemoveBillingProfileMutation),
      postStartWebPurchase: builder.mutation(postStartWebPurchaseMutation),
      postFinishWebPurchase: builder.mutation(postFinishWebPurchaseMutation),
      postPurchaseWithCasinoDollars: builder.mutation(postPurchaseWithCasinoDollarsMutation),
      getBillingProfiles: builder.query(getBillingProfilesQuery),
      getAddressForPurchase: builder.query(getAddressForPurchaseQuery),
      getRefreshActiveConsumables: builder.query(getRefreshActiveConsumablesQuery),
      getCurrencyPackages: builder.query(getCurrencyPackagesQuery)
    };
  }
});
purchaseApi.enhanceEndpoints({
  addTagTypes: PURCHASE_CACHE_TAGS.purchaseTagsArray
});
var usePostFinishMobilePurchaseMutation = purchaseApi.usePostFinishMobilePurchaseMutation,
  usePostBuyItemWithBillingProfileMutation = purchaseApi.usePostBuyItemWithBillingProfileMutation,
  useLazyGetAddressForPurchaseQuery = purchaseApi.useLazyGetAddressForPurchaseQuery,
  _purchaseApi$endpoint = purchaseApi.endpoints,
  getBillingProfiles = _purchaseApi$endpoint.getBillingProfiles,
  getRefreshActiveConsumables = _purchaseApi$endpoint.getRefreshActiveConsumables,
  getCurrencyPackages = _purchaseApi$endpoint.getCurrencyPackages,
  postFinishMobilePurchase = _purchaseApi$endpoint.postFinishMobilePurchase,
  postBuyItemWithBillingProfile = _purchaseApi$endpoint.postBuyItemWithBillingProfile,
  postRemoveBillingProfile = _purchaseApi$endpoint.postRemoveBillingProfile,
  postStartWebPurchase = _purchaseApi$endpoint.postStartWebPurchase,
  postFinishWebPurchase = _purchaseApi$endpoint.postFinishWebPurchase,
  postPurchaseWithCasinoDollars = _purchaseApi$endpoint.postPurchaseWithCasinoDollars;
export { usePostFinishMobilePurchaseMutation, usePostBuyItemWithBillingProfileMutation, useLazyGetAddressForPurchaseQuery, getBillingProfiles, getRefreshActiveConsumables, getCurrencyPackages, postFinishMobilePurchase, postBuyItemWithBillingProfile, postRemoveBillingProfile, postStartWebPurchase, postFinishWebPurchase, postPurchaseWithCasinoDollars };