import React from 'react';
import { Spinner } from 'react-bootstrap';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import {
  CheckBoxContainer,
  FormBody,
  Header,
  HeaderContent,
  HeaderImage,
  NeedHelpStyle,
  HelpTextContainer,
  MessageContainer,
  ModalFormBody,
  RegisterButtonContainer,
  Title,
  TitleHolder,
  SuccessAccountMessage,
  SuccessAccountWrapper,
} from '../common/styledComponents';
import CheckBoxes from './components/CheckBoxes';
import SignUpButton from './components/FormButton';
import theme from 'shared/assets/style/theme';
import useThemeContext from 'shared/context/hooks/useThemeContext';
import FormInputs from './components/FormInputs';
import LinkText from '../../../components/LinkText/LinkText';

export const SignUpForm = ({
  values,
  errors,
  status,
  handleSubmit,
  setFieldValue,
  handleBlur,
  handleChange,
  handleCheckBoxToggle,
  getStatusMessage,
  title,
  termsAndConditionsUrl,
  privacyPolicyUrl,
  onPressHelp,
  showPassword,
  showPasswordToggleHandle,
  isModal,
  isLandingPage,
  requireName,
  clientText,
  clientFurtherInfoText,
  isLoading,
  postSignupData,
}) => {
  const { acceptedTerms, emailOptIn } = values;
  const BodyComponent = isModal ? ModalFormBody : FormBody;
  const { SignUp } = useThemeContext();

  const disabled = !acceptedTerms || isLoading;
  const formStatusMessgae = getStatusMessage();

  return (
    <>
      {isModal && (
        <Header>
          <HeaderImage
            source={assetSource(ASSET_KEY.FULL_MODAL_HEADER)}
            ibw={require(asset`full-modal-header.ibw`)}
            style={{ position: 'absolute' }}
          >
            <HeaderContent>
              <TitleHolder>
                <Title data-label={(!!title && title) || ''}>{(!!title && title) || ''}</Title>
              </TitleHolder>
              <HelpTextContainer onClick={onPressHelp}>
                <LinkText customStyle={NeedHelpStyle} underline={false}>
                  {getText(TEXT_KEY.NEED_HELP)}
                </LinkText>
              </HelpTextContainer>
            </HeaderContent>
          </HeaderImage>
        </Header>
      )}
      {!postSignupData?.success ? (
        <BodyComponent>
          <FormInputs
            requireName={requireName}
            showPasswordToggleHandle={showPasswordToggleHandle}
            setFieldValue={setFieldValue}
            errors={errors}
            handleBlur={handleBlur}
            showPassword={showPassword}
            handleChange={handleChange}
            signUpTheme={SignUp}
            values={values}
          />

          <CheckBoxContainer>
            <CheckBoxes
              handleCheckBoxToggle={handleCheckBoxToggle}
              acceptedTerms={acceptedTerms}
              privacyPolicyUrl={privacyPolicyUrl}
              termsAndConditionsUrl={termsAndConditionsUrl}
              clientText={clientText}
              clientFurtherInfoText={clientFurtherInfoText}
              emailOptIn={emailOptIn}
            />
          </CheckBoxContainer>
          {formStatusMessgae && <MessageContainer success={status?.success}>{formStatusMessgae}</MessageContainer>}

          <RegisterButtonContainer>
            <SignUpButton
              isLandingPage={isLandingPage}
              isDebounce={true}
              disabled={disabled}
              onClick={handleSubmit}
              textStroke={theme.palette.success[2]}
            />
          </RegisterButtonContainer>
          {isLoading && (
            <Spinner
              style={{
                position: 'absolute',
                top: '25%',
                left: '32.5%',
              }}
              animation="grow"
              className="custom-spinner"
            />
          )}
        </BodyComponent>
      ) : (
        <SuccessAccountWrapper>
          <SuccessAccountMessage>{postSignupData.msg}</SuccessAccountMessage>
        </SuccessAccountWrapper>
      )}
    </>
  );
};

