import generateRender from 'shared/utils/generateRender';
import DefaultLinkAccountHeader from './DefaultLinkAccountHeader';
import HighlightedHeader from '../../../../components/JSModal/components/HighlightedHeader';

const ModalHeader = generateRender({
  default: DefaultLinkAccountHeader,
  millelacs: HighlightedHeader,
  jamul: HighlightedHeader,
  ladyluck: HighlightedHeader,
});

export default ModalHeader;

