import { connect } from 'react-redux';
import Tier from './Tier';
var mapStateToProps = function mapStateToProps(state) {
  return {
    memberLevel: state.playerInfo.memberLevel,
    isAccountLinked: state.playerInfo.isAccountLinked,
    casinoIdStatus: state.playerInfo.casinoIdStatus,
    coin: state.coin,
    activeConsumables: state.consumables.active,
    assetsUrls: state.app.assetsUrls,
    linkCardAssets: state.properties.data.linkCards,
    loyaltyCardComingSoon: state.properties.data.loyaltyCardComingSoon
  };
};
export default connect(mapStateToProps)(Tier);