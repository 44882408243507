import React from 'react';
import styled from 'styled-components';
import Utils from 'shared/utils/index';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import useAssetPreloader from 'shared/components/AssetPreloader/useAssetPreloader';
import useThemeContext from 'shared/context/hooks/useThemeContext';
import { SYSTEM_OS } from 'shared/constants';

const WidePreloader = ({ children }) => {
  const { percentLoaded } = useAssetPreloader();
  const { AssetPreloader } = useThemeContext();
  const isWindows = Utils.getSystemOs() === SYSTEM_OS.WINDOWS;

  return (
    <div
      className="AssetPreloader"
      style={{
        backgroundImage: `url(${assetSource(ASSET_KEY.LOADING_SCREEN)})`,
      }}
    >
      <LoadingBarContainer
        theme={AssetPreloader.wideLoadingBarcontainer}
        className={Utils.ieVersion() !== 0 ? ' ieHack' : ''}
      >
        <LoadingBarBacking src={assetSource(ASSET_KEY.LOADING_BAR_BACKING)} alt="" />
        <LoadingBarFillContainer>
          <LoadingBarFillSide src={assetSource(ASSET_KEY.LOADING_FILL_SIDE)} alt="" />
          <LoadingBarFillInner src={assetSource(ASSET_KEY.LOADING_FILL_CENTER)} percentLoaded={percentLoaded} alt="" />
          <LoadingBarFillSide
            windows={isWindows}
            src={assetSource(ASSET_KEY.LOADING_FILL_SIDE)}
            transform="true"
            alt=""
          />
        </LoadingBarFillContainer>
      </LoadingBarContainer>
      {children}
    </div>
  );
};

export default WidePreloader;

const LoadingBarContainer = styled.div`
  position: absolute;
  height: 7rem;
  width: 40rem;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  border-radius: 4em;
  ${({ theme }) => theme}
`;

const LoadingBarBacking = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
`;

const LoadingBarFillContainer = styled.div`
  display: flex;
  flex-direction: row;
  left: 0.25vw;
  position: absolute;
  height: 6.6rem;
  width: 98%;
  align-items: center;
`;

const LoadingBarFillSide = styled.img`
  height: ${({ windows, transform }) => (windows && transform ? 'calc(100% - 1px)' : '100%')};
  margin-bottom: ${({ windows, transform }) => (windows && transform ? 1 : 0)}px;
  transform: ${({ transform }) => (transform ? 'rotateY(180deg)' : 'none')};
`;

const LoadingBarFillInner = styled.img`
  height: 100%;
  width: ${({ percentLoaded }) => (percentLoaded > 3 ? percentLoaded - 10 : 0)}%;
  transition: all 1s ease;
  margin-inline: -1px;
`;

