import MissionsTutorialLayer from './MissionsTutorialLayer';
import { connect } from 'shared/node_modules/react-redux';

const mapStateToProps = state => {
  return {
    tutorial: state.tutorial,
    missionsFeature: state.missions.missionsFeature,
    gameId: state.gameInfo.gameId,
    missions: state.missions.data,
    missionsAssets: state.assetsPackage.assets.MISSIONS_FEATURE,
  };
};

export default connect(mapStateToProps)(MissionsTutorialLayer);

